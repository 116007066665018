import {useEffect, useRef, useState} from "react";
import {ContextMenu} from "./ContextMenu";
import * as React from "react";

export let HandleOnContext;

export function ImportContextMenu() {
    let contextMenuRef = useRef(null);

    let [contextMenu, setContextMenu] = useState({
        position: {
            x: 0,
            y: 0
        },
        toggled: false,
    });

    let [contextMenuButtons, setContextMenuButtons] = useState([]);

    useEffect(() => {
        function handler(event) {
            if (contextMenuRef && contextMenuRef.current) {
                if (!contextMenuRef.current.contains(event.target)) {
                    setContextMenu({
                        position: {
                            x: 0,
                            y: 0
                        },
                        toggled: false,
                    })
                }
            }
        }

        document.addEventListener('click', handler)

        return () => {
            document.removeEventListener('click', handler)
        }
    })

    HandleOnContext = (event, rightClickedItem, buttons) => {
        event.preventDefault();
        let contextMenuRefAttr = contextMenuRef.current.getBoundingClientRect();
        let isLeft = (event.clientX < window?.innerWidth / 2);

        let x = isLeft ? event.clientX : event.clientX - contextMenuRefAttr.width;
        let y = event.clientY;

        setContextMenuButtons(buttons);

        setContextMenu({
            rightClickedItem: rightClickedItem,
            position: {
                x: x,
                y: y
            },
            toggled: true,
        });
    }

    return (<ContextMenu
        contextMenuRef={contextMenuRef}
        x={contextMenu.position.x}
        y={contextMenu.position.y}
        isToggled={contextMenu.toggled}
        rightClickedItem={contextMenu.rightClickedItem}
        buttons={contextMenuButtons}
    />);
}