import "swagger-ui-react/swagger-ui.css"
import React, {useEffect, useState} from "react";
import {useRestfulApi} from "../../../hooks/useRestfulApi";
import StyledSwaggerUI from "../../../component/openApi/StyledSwaggerUI";


export default function ApiDocumentation({settings}) {
    let [doc, setDoc] = useState()
    let restfulApi = useRestfulApi();

    useEffect(() => {
        if (!doc) {
            let openApiLinkEndpoint = settings && settings.length
                ? settings
                    .filter(row => row.inUse)
                    .filter(row => row.settingName.toLowerCase() === "open-api-link-endpoint".toLowerCase())
                    .at(0)
                    .settingValue
                : "";

            let openApiLinkResource = settings && settings.length
                ? settings
                    .filter(row => row.inUse)
                    .filter(row => row.settingName.toLowerCase() === "open-api-link-resource".toLowerCase())
                    .at(0)
                    .settingValue
                : "";

            restfulApi?.request?.PostRequest({
                "endpoint": openApiLinkEndpoint,
                "resource": openApiLinkResource,
                "httpmethod": "GET"
            }, (data) => setDoc(data.response.data))
        }

        return () => doc;
    },[doc,setDoc,restfulApi?.request ,settings])

    return (
        <div className="max-h-[100vh] w-full m-auto overflow-auto">
            <StyledSwaggerUI spec={doc}/>
        </div>
    );
}