import React, {useEffect, useState} from "react";
import {Loader} from "../../component/Loader";
import {Variables} from "../../component/restful/Variables";
import {Environments} from "../../component/Environments";
import {Button} from "react-daisyui";
import {TfiSave} from "react-icons/tfi";
import {useRestfulApi} from "../../hooks/useRestfulApi";

export default function Settings() {
    let [variables, setVariables] = useState();
    let [environments, setEnvironments] = useState();
    let restfulApi = useRestfulApi();

    useEffect(() => {

        if (!environments) {
            restfulApi?.environment.GetEnvironment((data) => {
                setEnvironments(data);
            });
        }

        if (!variables) {
            restfulApi?.variable.GetVariables((data) => {
                setVariables(data);
            });
        }

    }, [environments, variables, restfulApi?.environment, restfulApi?.variable])


    return (<div className={"max-w-full w-full max-h-[90vh] h-[90vh] p-4"}>

        <h1 className={"pb-4 text-4xl"}>Settings</h1>

        <h1 className={"pl-4 text-3xl"}>Environments</h1>
        <Loader
            loading={!environments}
        >
            <Environments
                onChange={(environments) => {
                    setEnvironments(environments);
                }}
                data={environments ? environments : []}/>
        </Loader>
        <div className={"divider"}/>

        <h1 className={"pl-4 text-3xl"}>Variables</h1>
        <Loader
            loading={!variables}
        >
            <Variables
                environments={(environments ? environments : [])
                    .map((environment) => {
                        return {
                            key: environment.id,
                            value: environment.name,
                        }
                    })}
                onChange={(variable) => {
                    setVariables(variable);
                }}
                data={variables ? variables : []}/>
        </Loader>
        <div className={"divider"}/>

        <Button
            className={"join-item"}
            onClick={() => {

                setEnvironments(null);
                if (environments?.filter(environment => environment.id <= 0).length) {
                    restfulApi?.environment?.PostEnvironment(environments?.filter(environment => environment.id <= 0), (data) => {
                        restfulApi?.environment.GetEnvironment((data) => {
                            setEnvironments(data);
                        });
                    });
                }

                if (environments?.filter(environment => environment.id > 0).length) {
                    restfulApi?.environment?.PutEnvironment(environments?.filter(environment => environment.id > 0), (data) => {
                        restfulApi?.environment.GetEnvironment((data) => {
                            setEnvironments(data);
                        });
                    });
                }

                setVariables(null);
                if (variables?.filter(variable => variable.id <= 0).length) {
                    restfulApi?.variable?.PostVariables(variables?.filter(variable => variable.id <= 0), (data) => {
                        restfulApi?.variable?.GetVariables((data) => {
                            setVariables(data);
                        });
                    });
                }

                if (variables?.filter(variable => variable.id > 0).length) {
                    restfulApi?.variable?.PutVariables(variables?.filter(variable => variable.id > 0), (data) => {
                        restfulApi?.variable.GetVariables((data) => {
                            setVariables(data);
                        });
                    });
                }

            }} color={"secondary"}>
            <TfiSave/> Save
        </ Button>
    </div>);
}