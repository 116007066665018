import {Modal} from "./Modal";

export function OpenContinueCancelModal({children, visible, onClose, onCancel, onContinue, onOpen}) {

    let onCloseFunction = onClose ? onClose : () => {
    };
    let onContinueFunction = onContinue ? onContinue : () => {
    };
    let onCancelFunction = onCancel ? onCancel : () => {
    };
    let onOpenFunction = onOpen ? onOpen : () => {
    };

    return (
        <Modal
            onClose={onClose}
            visible={visible}
        >
            {children}
            <div className="modal-action">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-success" onClick={() => {
                    onOpenFunction();
                    onCloseFunction();
                }}>
                    Open
                </button>
                <button className="btn" onClick={() => {
                    onContinueFunction();
                    onCloseFunction();
                }}>
                    Continue
                </button>
                <button className="btn btn-error" onClick={() => {
                    onCancelFunction();
                    onCloseFunction();
                }}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
}