import React, {useEffect, useState} from "react";
import {CollectionList} from "../../../component/restful/CollectionList";
import {TabPage} from "../../../component/TabPage";
import {RiMailSendLine} from "react-icons/ri";
import {useRestfulApi} from "../../../hooks/useRestfulApi";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {Tabs} from "react-daisyui";

//https://react-icons.github.io/react-icons/icons/sl/
//https://react-icons.github.io/react-icons/icons/tfi/


function Restful() {
    let [collectionList, setCollectionList] = useState();
    let [restfulTabs, setRestfulTabs] = useLocalStorage('restfulTabs', []);
    let restfulApi = useRestfulApi();

    let [showCollections, setShowCollections] = useLocalStorage('showCollections', false);


    useEffect(() => {
        if (!collectionList) {
            restfulApi?.collection?.GetCollectionList(data => setCollectionList([...data]));
        }

        return () => collectionList;
    }, [collectionList, restfulApi?.collection]);

    return (
        <div className="w-full min-h-[90vh] block md:flex">
            <Tabs variant={"bordered"} className={"md:hidden"}>
                <Tabs.Tab active={showCollections}
                          onClick={() => setShowCollections(true)}>
                    Collections
                </Tabs.Tab>
                <Tabs.Tab active={!showCollections}
                          onClick={() => setShowCollections(false)}>
                    Requests
                </Tabs.Tab>
            </Tabs>

            <span className={"md:block" + (showCollections ? " block" : " hidden")}>
            <CollectionList
                collections={collectionList}

                onNewCollection={(collections, onUpdated) => restfulApi?.collection?.GetNewCollection((data) => onUpdated([...collections, data]))}
                onNewRequest={(collectionId, folderID, onUpdated) => restfulApi?.restfulDetail?.GetNewRestfulDetail(collectionId, folderID, () => onUpdated())}
                onNewFolder={(collectionId, folderID, onUpdated) => restfulApi?.folder?.GetNewFolder(collectionId, folderID, () => onUpdated())}

                onUpdateNameFolder={(folderId, value, onUpdate) => {
                    restfulApi?.folder?.GetFolder(folderId, (folder) => {
                        folder.name = value;
                        restfulApi?.folder?.PutFolder(folder, (data) => onUpdate(data))
                    })
                }}

                onUpdateNameRequest={(restDetailId, value, onUpdate) => {
                    restfulApi?.restfulDetail?.GetRestfulDetail(restDetailId, (restDetail) => {

                        if (value) {
                            restDetail.label = value;
                        }

                        restfulApi?.restfulDetail?.PutRestfulDetail(restDetail, (data) => onUpdate(data));
                    })
                }}

                onGetRequest={(restDetailId, setData) => restfulApi?.restfulDetail?.GetRestfulDetail(restDetailId, setData)}
                onDuplicateRequest={(restDetail, setData) => restfulApi?.restfulDetail?.PostRestfulDetail(restDetail, (dupData) => {
                        dupData.request.variables = restDetail?.request?.variables;
                        setData(dupData);
                    }
                )}
                onPutRequest={(restDetail, setData) => restfulApi?.restfulDetail?.PutRestfulDetail(restDetail, setData)}

                onDeleteCollection={(collection, setData) => restfulApi.collection.DeleteCollection(collection, setData)}
                onDeleteRequest={(restDetail, onUpdate) => restfulApi?.restfulDetail?.DeleteRestfulDetail(restDetail, (data) => onUpdate(data))}
                onDeleteFolder={(folderId, setData) => restfulApi.folder.GetFolder(folderId, folder => restfulApi?.folder?.DeleteFolder(folder, setData))}


                onCollectionsUpdated={() => {
                    restfulApi?.collection?.GetCollectionList(data => setCollectionList([...data]));
                }}
                onOpenCollection={(collection) => {
                    if (restfulTabs?.filter(tab => tab.key === "_" + collection.id).length) {
                        let tab = restfulTabs?.filter(tab => tab.key === "_" + collection.id);
                        tab.focus = true;
                        setRestfulTabs([...restfulTabs]);
                        return;
                    }

                    setRestfulTabs([...restfulTabs, {
                        key: "_" + collection.id,
                        id: collection.id,
                        type: "CollectionPage",
                        label: collection.name,
                        focus: true,
                        onSave: () => {
                            restfulApi?.collection?.GetCollectionList(data => setCollectionList([...data]));
                        },
                        onDelete: () => {
                            restfulApi?.collection?.GetCollectionList(data => {
                                setRestfulTabs(...restfulTabs?.filter(tab => tab.key !== "_" + collection.id))
                                setCollectionList([...data]);
                            });
                        },
                        onChange: () => {
                        }
                    }]);
                }}
                onSelectRequest={(collectionId, restDetail) => {
                    if (restfulTabs?.filter(tab => tab.key === restDetail.id + "_" + restDetail.collectionId).length) {
                        let tab = restfulTabs?.filter(tab => tab.key === restDetail.id + "_" + restDetail.collectionId);
                        tab.focus = true;
                        setRestfulTabs([...restfulTabs]);
                        return;
                    }
                    if (!restfulTabs) {
                        restfulTabs = []
                    }

                    setRestfulTabs([...restfulTabs, {
                        key: restDetail.id + "_" + restDetail.collectionId,
                        id: restDetail.id,
                        type: "RestRequest",
                        focus: !restfulTabs || restfulTabs.length <= 0,
                        label: restDetail.httpmethod + ": " + restDetail.label,
                        onSave: () => {
                            restfulApi?.collection?.GetCollectionList(data => setCollectionList([...data]));
                        },
                        onDelete: () => {
                            restfulApi?.collection?.GetCollectionList(data => {
                                setRestfulTabs(...restfulTabs?.filter(tab => tab.key !== restDetail.id + "_" + restDetail.collectionId))
                                setCollectionList([...data]);
                            });
                        },
                        onChange: () => {
                        }
                    }]);
                }}
            />
        </span>

            <span className={"w-full md:max-w-full md:block" + (showCollections ? " hidden" : " block")}>
            {restfulTabs?.length
                ? <TabPage
                    localStorageKey={'restful'}
                    tabs={restfulTabs}
                    onOpen={(tab) => {
                        let tabToUpdate = restfulTabs?.filter(tabToUpdate => tabToUpdate.key === tab.key);
                        tabToUpdate.focus = false;
                        setRestfulTabs([...restfulTabs]);
                    }}
                    onchange={(tab) => {
                        let update = restfulTabs?.filter(row => row.key === tab.key).at(0);
                        update.changed = true;
                        setRestfulTabs([...restfulTabs]);
                    }}
                    onSave={(tab) => {
                        let update = restfulTabs?.filter(row => row.key === tab.key).at(0);
                        update.changed = false;
                        update.label = tab.label;
                        setRestfulTabs([...restfulTabs]);

                        restfulApi?.collection?.GetCollectionList(data => setCollectionList([...data]));
                    }}
                    onClose={(tab) => {
                        let update = restfulTabs?.filter(row => row.key !== tab.key);
                        setRestfulTabs([...update]);
                    }}
                />
                : <div className="grid max-w-full w-full max-h-full h-full place-items-center">
                    <div className={"text-[10rem] md:text-[30rem] text-base-content"}>
                        <RiMailSendLine/>
                    </div>
                </div>}
        </span>


        </div>
    );
}

export default Restful;