export function RestfulApi(){
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
        // dev code
        return "http://localhost:30000"
    } else {
        return window.location.origin.toString()
    }
}

export function MessagingApi(){
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
        // dev code
        return "http://localhost:30000"
    } else {
        return window.location.origin.toString()
    }
}

export function ContentApi(){
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
        // dev code
        return "http://localhost:30000"
    } else {
        return window.location.origin.toString()
    }
}

