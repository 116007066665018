import {restPost} from "../../hooks/rest";

const requestUrl = "/Request/v1/";

export function RequestController(endpoint) {
    return {
        PostRequest: (restDetail, setData) => PostRequest(endpoint, restDetail, setData),
    }
}

function PostRequest(endpoint, restDetail, setData) {
    let rest = restPost(endpoint, requestUrl);
    rest(restDetail, [], new Map([["Content-Type", "application/json"]]), setData);
}