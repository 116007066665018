import React from "react";
import {Variables} from "../restful/Variables";
import {Modal} from "./Modal";

export function VariableModal({variables, visible, onClose, onChange, onDelete,environments}) {

    let onCloseFunction = onClose ? onClose : () => {
    };

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };

    return (
        <Modal
            onClose={onClose}
            visible={visible}
            isLarge={true}
        >
            <div className="h-[80%] overflow-auto">
                <h1 className={"text-3xl pl-4 pt-4"}>Variables</h1>
                <Variables
                    onChange={onChangeFunction}
                    environments={environments}
                    data={variables}
                    onDelete={() => onDeleteFunction()}
                />
            </div>
            <div className="modal-action">
                <button className="btn btn-error" onClick={() => {
                    onCloseFunction();
                }}>
                    Close
                </button>
            </div>
        </Modal>
    );
}