import {Button, Tabs} from "react-daisyui";
import {IoClose} from "react-icons/io5";
import React, {useEffect} from "react";
import {RestRequest} from "../view/tools/restful/RestRequest";
import {CollectionPage} from "../view/tools/restful/CollectionPage";
import {HandleOpenModal} from "./modal/ModalFactory";
import {useRestfulApi} from "../hooks/useRestfulApi";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {FakerConfig} from "../view/tools/faker/FakerConfig";
import {ApiPage} from "../view/tools/faker/ApiPage";

export function TabPage({localStorageKey, tabs, onchange, onSave, onOpen, onClose}) {
    let [displayRestfulTab, setDisplayRestfulTab] = useLocalStorage(localStorageKey + 'DisplayRestfulTab', null);
    let [tabHistory, setTabHistory] = useLocalStorage(localStorageKey + 'TabHistory', []);
    let restfulApi = useRestfulApi();

    let onCloseFunction = onClose ? onClose : () => {
    };

    let onOpenFunction = onOpen ? onOpen : () => {
    };

    let onSaveFunction = onSave ? onSave : () => {
    };

    let onChangeFunction = onchange ? (tab) => {
        onchange(tab)
    } : () => {
    };


    function closeTabWindow(tab, tabHistory, setTabHistory) {
        if (tab.changed) {
            HandleOpenModal({
                title: 'Save or close?',
                type: 'SaveContinueCancel',
                onContinue: () => {
                    tab.changed = false;
                    closeTabWindow(tab, tabHistory, setTabHistory);
                },
                onSave: () => {
                    onSaveFactory(restfulApi, tab, () => {
                        onSaveFunction(tab);
                        tab.onSave(tab.data);
                        closeTabWindow(tab, tabHistory, setTabHistory);
                    })
                },
                content: <>
                    <p className="pt-4">You are about to Leave the page without saving.</p>
                    <p className="pt-1 pb-4">Would you like to continue?</p>
                </>,
            })
            return;
        }


        let updatedTabHistory = tabHistory.filter(th => th !== tab.key)
        setTabHistory([...updatedTabHistory]);

        if (updatedTabHistory.length <= 0) {
            setDisplayRestfulTab(null);
            onCloseFunction(tab);
            return;
        }

        let key = updatedTabHistory.at(updatedTabHistory.length - 1);
        let newTab = tabs.filter(th => th.key === key).at(0);

        setDisplayRestfulTab(newTab);
        onCloseFunction(tab);
    }

    useEffect(() => {
        if (!displayRestfulTab) {
            setDisplayRestfulTab(tabs?.filter(tab => tab.focus)?.at(0));
        }

        return () => displayRestfulTab;
    }, [displayRestfulTab,setDisplayRestfulTab ,tabs])


    return (
        <div className="max-w-full w-full overflow-x-auto">
            {tabs ? <div className={"max-w-full w-full max-h-full overflow-auto"}>
                    <Tabs variant={"lifted"} className={"flex"}>
                        {tabs.filter(tab => tab).map(tab =>
                            <Tabs.Tab key={tab.id}
                                      active={displayRestfulTab && tab.key === (displayRestfulTab ? displayRestfulTab
                                          : tabs.filter(tab => tab.focus).at(0)).key}
                                      onMouseDown={(event) => {
                                          if (event.button === 1) {
                                              closeTabWindow(tab, tabHistory, setTabHistory);
                                          }
                                      }}
                            >
                                <span className={"flex gap-2"}>
                                    {!tab.changed ? null :
                                        <span className={"bg-accent rounded-full p-1 max-h-1 max-w-1 mt-1"}/>}
                                    <p className={"truncate ... max-w-40"}
                                       onClick={() => {
                                           if (tabs.filter(filterTab => filterTab.key === tab.key).length > 0) {
                                               setTabHistory([...tabHistory, tab.key])
                                               onOpenFunction(tab);
                                               setDisplayRestfulTab(tab);
                                           }
                                       }}>
                                        {tab.label}
                                    </p>
                                    <Button size={"xs"} className={"bg-base-100 border-0"}
                                            onClick={() => closeTabWindow(tab, tabHistory, setTabHistory)}
                                    >
                                        <IoClose/>
                                    </Button>
                            </span>
                            </Tabs.Tab>)}
                    </Tabs>
                </div>
                : null}
            <div>
                {displayRestfulTab ? <TabFactory
                    tab={displayRestfulTab}
                    onChange={(tab) => {
                        displayRestfulTab = tab;
                        setDisplayRestfulTab(displayRestfulTab);
                        onChangeFunction(tab);
                    }}
                    onSave={() => {
                        onSaveFunction(displayRestfulTab);
                    }}
                /> : null}
            </div>
        </div>
    )
}

function TabFactory({tab, onChange, onSave}) {
    if (!tab) {
        return <></>
    }

    let onSaveFunction = tab.onSave ? (tab) => {
        onSave(tab.label);
        tab.onSave(tab);
    } : () => {
        onSave();
    };

    let onDelete = tab.onDelete ? tab.onDelete : () => {
    };

    let onChangeFunction = tab.onChange ? (tab) => {
        onChange(tab);
        tab.onChange(tab);
    } : () => {
        onChange(tab);
    };

    switch (tab.type.toLowerCase()) {
        case "RestRequest".toLowerCase():
            return (<RestRequest
                key={tab.key}
                tabKey={tab.key}
                id={tab.id}
                data={tab.data}
                onSave={(data) => {
                    tab.data = data;
                    tab.label = data.httpmethod + ": " + data.label;
                    onSaveFunction(tab);
                }}
                onDelete={onDelete}
                onChange={(data) => {
                    tab.data = data;
                    onChangeFunction(tab)
                }}
            />);
        case "CollectionPage".toLowerCase():
            return (<CollectionPage
                key={tab.key}
                tabKey={tab.key}
                collectionId={tab.id}
                data={tab.data}
                onSave={(data) => {
                    tab.data = data;
                    tab.label = data.name;
                    onSaveFunction(tab);
                }}
                onDelete={onDelete}
                onChange={(data) => {
                    tab.data = data;
                    onChangeFunction(tab)
                }}
            />);

            case "FakerConfig".toLowerCase():
            return (<FakerConfig
                key={tab.key}
                tabKey={tab.key}
                mockId={tab.id}
                data={tab.data}
                onSave={(data) => {
                    tab.data = data;
                    tab.label = data.httpMethod + ": " + data.name;
                    onSaveFunction(tab);
                }}
                onDelete={onDelete}
                onChange={(data) => {
                    tab.data = data;
                    onChangeFunction(tab)
                }}
            />);

            case "ApiPage".toLowerCase():
            return (<ApiPage
                key={tab.key}
                tabKey={tab.key}
                apiId={tab.id}
                data={tab.data}
                onSave={(data) => {
                    tab.data = data;
                    tab.label = data.name;
                    onSaveFunction(tab);
                }}
                onDelete={onDelete}
                onChange={(data) => {
                    tab.data = data;
                    onChangeFunction(tab)
                }}
            />);
        default:
            return <></>;
    }
}

function onSaveFactory(restfulApi, tab, setData) {
    if (!tab) {
        return;
    }

    let setDataFunction = setData ? setData : () => {
    };

    switch (tab.type.toLowerCase()) {
        case "RestRequest".toLowerCase():
            return restfulApi?.restfulDetail?.PutRestfulDetail(tab.data, setDataFunction);
        case "CollectionPage".toLowerCase():
            return restfulApi?.collection?.PutCollection(tab.data, setDataFunction);
        default:
            return;
    }
}