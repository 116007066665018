import {TfiPencilAlt, TfiTrash} from "react-icons/tfi";
import React from "react";
import {BsCollection} from "react-icons/bs";
import {HandleOnContext} from "../ImportContextMenu";
import {RiMailAddLine} from "react-icons/ri";
import {FiFolderPlus} from "react-icons/fi";
import {FolderListItem} from "./FolderListItem";
import {RestRequestListItem} from "./RestRequestListItem";
import {HandleOpenModal} from "../modal/ModalFactory";
import {IoAddOutline} from "react-icons/io5";

export default function CollectionListItem({
                                               collections,
                                               onCollectionUpdate,
                                               onOpenCollection,
                                               onGetRequest,
                                               onDuplicateRequest,
                                               onPutRequest,

                                               onNewRequest,
                                               onNewFolder,

                                               onUpdateNameFolder,
                                               onUpdateNameRequest,

                                               onDeleteCollection,
                                               onDeleteRequest,
                                               onDeleteFolder,

                                               onSelectRequest,
                                               filter
                                           }) {

    return (
        <>
            {collections?.length ?
                collections.filter(collection =>
                    collection?.name?.toLowerCase().includes(filter)
                    || collection?.restfulDetails?.filter(request => request.label.toLowerCase().includes(filter)).length
                    || collection?.folders?.filter(folder => folder.name.toLowerCase().includes(filter)).length)
                    .map(collection =>
                        <ul key={collection.id} className={"menu rounded-lg w-full md:max-w-[10rem]"}>
                            <li>
                                <details>
                                    <summary className={"flex  overflow-clip"} data-tip={collection.name}
                                             onContextMenu={event => HandleOnContext(event, collection.id,
                                                 getContextButtons(onNewRequest,
                                                     onNewFolder,
                                                     onCollectionUpdate,
                                                     () => onOpenCollection(collection),
                                                     () => {
                                                         HandleOpenModal({
                                                             title: 'Delete collection',
                                                             type: 'confirmCancel',
                                                             onConfirm: () => onDeleteCollection(collection, onCollectionUpdate),
                                                             content: <>
                                                                 <p className="pt-4">You are about to delete this
                                                                     collection.</p>
                                                                 <p className="pt-1 pb-4">Are you sure you want to
                                                                     delete
                                                                     it?</p>
                                                             </>,
                                                         });
                                                     }
                                                 ))}>
                                        <BsCollection/>
                                        <a className={"truncate ... text-m font-medium md:max-w-[10rem]"}>
                                            {collection.name}
                                        </a>
                                    </summary>
                                    {(collection?.folders?.length || collection?.restfulDetails?.length)
                                        ? <ul className={"menu-xs md:max-w-[10rem]"}>
                                            <FolderListItem
                                                collectionId={collection.id}
                                                collections={collections}
                                                filter={filter}
                                                folders={collection.folders ? collection.folders : []}
                                                onSelectRequest={onSelectRequest}
                                                onUpdate={onCollectionUpdate}
                                                onPutRequest={onPutRequest}
                                                onDuplicateRequest={onDuplicateRequest}

                                                onGetRequest={onGetRequest}

                                                onNewRequest={onNewRequest}
                                                onNewFolder={onNewFolder}

                                                onUpdateNameFolder={onUpdateNameFolder}
                                                onUpdateNameRequest={onUpdateNameRequest}

                                                onDeleteFolder={onDeleteFolder}
                                                onDeleteRequest={onDeleteRequest}
                                            />
                                            <RestRequestListItem
                                                collections={collections}
                                                collectionId={collection.id}
                                                filter={filter}
                                                restfulDetails={collection.restfulDetails ? collection.restfulDetails : []}
                                                onSelectRequest={onSelectRequest}
                                                onUpdate={onCollectionUpdate}
                                                onPutRequest={onPutRequest}
                                                onGetRequest={onGetRequest}
                                                onDeleteRequest={onDeleteRequest}

                                                onDuplicateRequest={onDuplicateRequest}
                                                onUpdateNameRequest={onUpdateNameRequest}
                                            />
                                        </ul>
                                        : <ul className={""}>
                                            <p className={"menu-title whitespace-break-spaces md:max-w-[10rem]"}>No
                                                requests
                                                present.</p>
                                            <p className={"whitespace-break-spaces md:max-w-[10rem]"}>It Looks like
                                                you
                                                Dont
                                                have any requests in this collection.</p>
                                            <p className={"whitespace-break-spaces md:max-w-[10rem] mt-2 hidden md:block"}>Do
                                                you
                                                want
                                                to:</p>
                                            <li>
                                                <a
                                                    className={"bg-secondary text-secondary-content hidden md:block"}
                                                    onClick={() => {
                                                        onNewRequest(collection.id, 0, () => onCollectionUpdate ? onCollectionUpdate(collection.id) : () => {
                                                        })
                                                    }}
                                                >
                                                    <RiMailAddLine/> Add new request
                                                </a>
                                            </li>
                                            <p className={"whitespace-break-spaces md:max-w-[10rem] mt-2"}>You can
                                                add more
                                                requests
                                                by right clicking the controller
                                                selection the following option:
                                                <kbd className="kbd"><RiMailAddLine/> Add new request</kbd></p>
                                        </ul>
                                    }
                                </details>
                            </li>
                        </ul>) :
                <div>
                    <div>
                        There are no collections, folders or requests present for the filter: {filter}
                    </div>
                </div>}
        </>
    );
}

function getContextButtons(onNewRequest, onNewFolder, onCollectionUpdate, onOpenCollection, onDeleteCollection) {
    return [
        {
            content: <><IoAddOutline/> New</>,
            isGroup: true,
            isOpen: true,
            buttons: [
                {
                    content: <><RiMailAddLine/> Add new request</>,
                    onClick: (collectionId) => {
                        onNewRequest(collectionId, 0, () => onCollectionUpdate ? onCollectionUpdate(collectionId) : () => {
                        });
                    },
                },
                {
                    content: <><FiFolderPlus/>Add new Folder</>,
                    onClick: (collectionId) => {
                        onNewFolder(collectionId, 0, () => onCollectionUpdate ? onCollectionUpdate(collectionId) : () => {
                        });
                    },
                },
            ]
        },
        {
            content: <><TfiPencilAlt/> Maintain</>,
            isGroup: true,
            buttons: [
                {
                    content: <><TfiPencilAlt/> Edit</>,
                    onClick: (collectionId) => onOpenCollection ? onOpenCollection(collectionId) : () => {
                    },
                },
                {
                    content: <><TfiTrash/> Delete</>,
                    onClick: (collectionId) => onDeleteCollection ? onDeleteCollection(collectionId) : () => {
                    },
                }
            ]
        },
    ];
}