import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const APIUrl = "/API/v1/";
const newAPIUrl = APIUrl + "new";
const listAPIUrl = APIUrl + "list";

export function APIController(endpoint) {
    return {
        GetNewAPI: (setData) => GetNewAPI(endpoint, setData),
        GetAPI: (id, setData) => GetAPI(endpoint, id, setData),
        GetAPIList: (setData) => GetAPIList(endpoint, setData),
        PostAPI: (API, setData) => PostAPI(endpoint, API, setData),
        PutAPI: (API, setData) => PutAPI(endpoint, API, setData),
        DeleteAPI: (API, setData) => DeleteAPI(endpoint, API, setData),
    }
}

function GetNewAPI(endpoint, setData) {
    let rest = restGet(endpoint, newAPIUrl);
    rest([], [], setData);
}

function GetAPI(endpoint, id, setData) {
    let rest = restGet(endpoint, APIUrl);
    rest(new Map([["id", id]]), [], setData);
}

function GetAPIList(endpoint, setData) {
    let rest = restGet(endpoint, listAPIUrl);
    rest([], [], setData);
}

function PostAPI(endpoint, API, setData) {
    let rest = restPost(endpoint, APIUrl);
    rest(API, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutAPI(endpoint, API, setData) {
    let rest = restPut(endpoint, APIUrl);
    rest(API, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteAPI(endpoint, API, setData) {
    let rest = restDelete(endpoint, APIUrl);
    rest(API, [], new Map([["Content-Type", "application/json"]]), setData);
}