import * as React from 'react'
import {useEffect, useRef, useState} from "react";
import {Button, Input, Table, Toggle} from "react-daisyui";
import {IoAddOutline} from "react-icons/io5";
import {TfiTrash} from "react-icons/tfi";
import {GetCurrentOpenModal, HandleOpenModal} from "../modal/ModalFactory";

export function Script({data, onChange, onDelete}) {
    const containerRef = useRef(null);
    const [scripts, setScripts] = useState(data?.filter(row => row.inUse)?.length
        ? data
        : [{
            id: data?.length
                ? (Math.min(...data.map(row => row.id)) >= -1 ? -1 : Math.min(...data.map(row => row.id))) - 1
                : -1,
            key: "",
            value: "",
            description: "",
            enable: true,
            inUse: true,
        }]);

    useEffect(() => {
        // Scroll to the bottom when messages update
        containerRef.current.scrollIntoView({behavior: 'smooth'});
    }, [data]);

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };

    return (
        <>
            <Table size={"xs mb-2"}>
                <Table.Head>
                    <span key={1}>Enable</span>
                    <span key={2}>key</span>
                    <span key={3}>Script</span>
                    <span key={5}>Description</span>
                    <span key={7}>Remove</span>
                </Table.Head>
                <Table.Body>
                    {scripts
                        .filter(row => row.inUse)
                        .map(row =>
                            <Table.Row key={row.id} hover={true}>
                                <Toggle
                                    size={"xs"}
                                    checked={row.enable}
                                    color={"success"}
                                    onChange={event => {
                                        let param = scripts.filter(param => param.id === row.id).at(0);
                                        param.enable = event.target.checked;
                                        setScripts([...scripts]);
                                        onChangeFunction([...scripts]);
                                    }}
                                />
                                <Input
                                    size={"xs"}
                                    className={"w-full"}
                                    placeholder={"Variable"}
                                    value={row.key}
                                    onChange={event => {
                                        let param = scripts.filter(param => param.id === row.id).at(0);
                                        param.key = event.target.value;
                                        setScripts([...scripts]);
                                        onChangeFunction([...scripts]);
                                    }}
                                />
                                <div className={"flex join"}>
                                    <Input
                                        size={"xs"}
                                        className={"w-full join-item"}
                                        placeholder={"Script"}
                                        value={row.value}
                                        disabled={true}
                                    />
                                    <Button size={"xs"}
                                            className={"join-item"}
                                            onClick={() => {
                                                let modal = GetCurrentOpenModal();

                                                HandleOpenModal({
                                                    type: 'CodeEditor',
                                                    onChange: content => {
                                                        let param = scripts.filter(param => param.id === row.id).at(0);
                                                        param.value = content;
                                                        setScripts([...scripts]);
                                                        onChangeFunction([...scripts]);
                                                    },
                                                    onClose: () => {
                                                        modal.content = [...scripts];
                                                        HandleOpenModal(modal);
                                                    },
                                                    language: 'javaScript',
                                                    content: row.value,
                                                });
                                            }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                                <Input
                                    size={"xs"}
                                    className={"w-full"}
                                    placeholder={"Description"}
                                    value={row.description}
                                    onChange={event => {
                                        let param = scripts.filter(param => param.id === row.id).at(0);
                                        param.description = event.target.value;
                                        setScripts([...scripts]);
                                        onChangeFunction([...scripts]);
                                    }}/>
                                <Button size={"xs"} className={"bg-base-100 border-0"}
                                        onClick={() => {
                                            let modal = GetCurrentOpenModal();

                                            HandleOpenModal({
                                                title: 'Delete variable',
                                                type: 'confirmCancel',
                                                onConfirm: () => {
                                                    let param = scripts.filter(param => param.id === row.id).at(0);
                                                    param.inUse = false;
                                                    setScripts([...scripts]);
                                                    onChangeFunction([...scripts]);
                                                    onDeleteFunction();
                                                },
                                                onClose: () => {
                                                    modal.content = [...scripts];
                                                    HandleOpenModal(modal);
                                                },
                                                content: <>
                                                    <p className="pt-4">You are about to delete this variable.</p>
                                                    <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
                                                </>,
                                            })
                                        }}
                                >
                                    <TfiTrash/>
                                </Button>
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>
            <Button
                size={"sm"}
                className={"w-[70%] mx-[15%]"}
                variant={"outline"}
                onClick={event => {
                    event.preventDefault();
                    setScripts([...scripts, {
                        id: Math.min(...scripts.map(row => row.id)) > -1 ? -1
                            : Math.min(...scripts.map(row => row.id)) - 1,
                        key: "",
                        value: "",
                        description: "",
                        enable: true,
                        inUse: true,
                    }]);
                }}
            >
                <IoAddOutline/>
                Add Variable
            </Button>
            <div ref={containerRef}></div>
        </>

    )
}