export function Rest(httpmethod,
                     endpoint,
                     resource) {
    const options = {
        httpmethod: httpmethod,
        url: new URL(resource, endpoint),
    }

    return (
        request,
        queryParameters,
        headerParameters,
        setData) => {

        if (queryParameters) {
            queryParameters.forEach(
                function (value, key) {
                    options.url.searchParams.append(key, value);
                })
        }


        if (!headerParameters)
            headerParameters = [];

        const requestOptions =
            httpmethod === "GET" || httpmethod === "HEAD" ?
                {
                    method: httpmethod,
                    headers: headerParameters,
                } : {
                    method: httpmethod,
                    headers: headerParameters,
                    body: JSON.stringify(request)
                };

        fetch(options.url.href.toString().trim(), requestOptions)
            .then(response => response.json())
            .then(json => setData(json))
            .catch(error => console.error(error));
    }
}

export function restGet(endpoint, resource) {
    const rest = Rest('GET', endpoint, resource);

    return (queryParameters, headerParameters, setData) =>
        rest(null, queryParameters, headerParameters, setData);
}

export function restPost(endpoint, resource) {
    const rest = Rest('POST', endpoint, resource);

    return (
        request, queryParameters, headerParameters, setData) =>
        rest(request, queryParameters, headerParameters, setData);
}

export function restPut(endpoint, resource) {
    const rest = Rest('PUT', endpoint, resource);

    return (
        request, queryParameters, headerParameters, setData) =>
        rest(request, queryParameters, headerParameters, setData);
}

export function restDelete(endpoint, resource) {
    const rest = Rest('Delete', endpoint, resource);

    return (
        request, queryParameters, headerParameters, setData) =>
        rest(request, queryParameters, headerParameters, setData);
}