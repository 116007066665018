export function ContextMenu({
                                rightClickedItem,
                                x,
                                y,
                                isToggled,
                                buttons,
                                contextMenuRef
                            }) {

    return (<menu
        style={{
            top: y + 2 + 'px',
            left: x + 2 + 'px',
        }}
        ref={contextMenuRef}
        className={(isToggled ? 'absolute visible' : 'invisible overflow-clip max-w-0  max-h-0')}>
        <div className={"menu menu-xs bg-base-300 rounded-box z-[99] w-52 p-2 shadow text-md"}>
            <LoadMenu
                buttons={buttons}
                rightClickedItem={rightClickedItem}
            />
        </div>
    </menu>)

}

function LoadMenu({buttons, rightClickedItem}) {
    return (<>
        {!buttons ? null : <ul
            className={"menu menu-xs"}>

            {buttons.map((button, index) => {
                function handleClick(event) {
                    event.stopPropagation();
                    button.onClick(rightClickedItem);
                }

                if (button.isTitle) {
                    return (<li key={index}>
                        <h2 className="menu-title">{button.content}</h2>
                        <LoadMenu
                            buttons={button.buttons}
                            rightClickedItem={rightClickedItem}
                        />
                    </li>);
                }

                if (button.isGroup && button.isOpen) {
                    return (
                        <li key={index}>
                            <details open>
                                <summary>{button.content}</summary>
                                <LoadMenu
                                    buttons={button.buttons}
                                    rightClickedItem={rightClickedItem}
                                />
                            </details>
                        </li>);
                }

                if (button.isGroup && !button.isOpen) {
                    return (
                        <li key={index}>
                            <details>
                                <summary>{button.content}</summary>
                                <LoadMenu
                                    buttons={button.buttons}
                                    rightClickedItem={rightClickedItem}
                                />
                            </details>
                        </li>);
                }

                if (button.isSpacer) {
                    return (<li key={index}>
                        <div className="divider"></div>
                        <LoadMenu
                            buttons={button.buttons}
                            rightClickedItem={rightClickedItem}
                        />
                    </li>);
                }

                return (
                    <li key={index}>
                        <span onClick={handleClick}>
                            {button.content}
                        </span>
                        <LoadMenu
                            buttons={button.buttons}
                            rightClickedItem={rightClickedItem}
                        />
                    </li>
                );
            })}
        </ul>
        }
    </>)
}