import {restGet} from "../../hooks/rest";

const linkUrl = "/href/v1/";

export function LinkController(endpoint) {
    return {
        GetLink: (setData) => GetLink(endpoint, setData),
    }
}

function GetLink(endpoint, setData) {
    let rest = restGet(endpoint, linkUrl);
    rest([], [], setData);
}

