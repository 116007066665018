import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const MockUrl = "/mock/v1/";
const newMockUrl = MockUrl + "new";
const listMockUrl = MockUrl + "list";

export function MockController(endpoint) {
    return {
        GetNewMock: (appId,setData) => GetNewMock(endpoint, appId, setData),
        GetMock: (id, setData) => GetMock(endpoint, id, setData),
        GetMockList: (setData) => GetMockList(endpoint, setData),
        PostMock: (Mock, setData) => PostMock(endpoint, Mock, setData),
        PutMock: (Mock, setData) => PutMock(endpoint, Mock, setData),
        DeleteMock: (Mock, setData) => DeleteMock(endpoint, Mock, setData),
    }
}

function GetNewMock(endpoint, appId, setData) {
    let rest = restGet(endpoint, newMockUrl);
    rest(new Map([["apiId", appId]]), [], setData);
}

function GetMock(endpoint, id, setData) {
    let rest = restGet(endpoint, MockUrl);
    rest(new Map([["id", id]]), [], setData);
}

function GetMockList(endpoint, setData) {
    let rest = restGet(endpoint, listMockUrl);
    rest([], [], setData);
}

function PostMock(endpoint, Mock, setData) {
    let rest = restPost(endpoint, MockUrl);
    rest(Mock, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutMock(endpoint, Mock, setData) {
    let rest = restPut(endpoint, MockUrl);
    rest(Mock, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteMock(endpoint, Mock, setData) {
    let rest = restDelete(endpoint, MockUrl);
    rest(Mock, [], new Map([["Content-Type", "application/json"]]), setData);
}