import {ConfirmCancelModal} from "./ConfirmCancelModal";
import React, {useState} from "react";
import {VariableModal} from "./VariableModal";
import {CodeEditorModal} from "./CodeEditorModal";
import {ContinueCancelModal} from "./ContinueCancelModal";
import {InputModal} from "./InputModal";
import {SelectModal} from "./SelectModal";
import {SaveContinueCancelModal} from "./SaveContinueCancelModal";
import {OpenContinueCancelModal} from "./OpenContinueCancelModal";
import {ScriptModal} from "./ScriptModal";

export let HandleOpenModal;
export let GetCurrentOpenModal;

export function ModalFactory() {
    let [modal, setModal] = useState({});

    HandleOpenModal = (modal) => {
        modal.visible = true;
        setModal(modal);
    }

    GetCurrentOpenModal = () => {
        return modal;
    }

    if (!modal || !modal.type) {
        return (<></>)
    }
    let onClose = modal.onClose ? () => {
        setModal(null);
        modal.onClose();
    } : () => setModal(null);

    let onCancel = modal.onCancel ? modal.onCancel : () => {
    };

    let onConfirm = modal.onConfirm ? modal.onConfirm : () => {
    };

    let onDelete = modal.onDelete ? modal.onDelete : () => {
    };

    let onChange = modal.onChange ? modal.onChange : () => {
    };

    let onContinue = modal.onContinue ? modal.onContinue : () => {
    };

    let onSave = modal.onSave ? modal.onSave : () => {
    };

    let onOpen = modal.onOpen ? modal.onOpen : () => {
    };

    switch (modal.type.toLowerCase()) {
        case "OpenContinueCancel".toLowerCase():
            return (
                <OpenContinueCancelModal visible={modal.visible}
                                    onClose={onClose}
                                    onCancel={onCancel}
                                    onContinue={onContinue}
                                    onOpen={onOpen}
                >
                    {modal?.title ? <h3 className="font-bold text-lg"> {modal?.title}</h3> : null}
                    {modal.content}
                </OpenContinueCancelModal>)
        case "SaveContinueCancel".toLowerCase():
            return (
                <SaveContinueCancelModal visible={modal.visible}
                                    onClose={onClose}
                                    onCancel={onCancel}
                                    onContinue={onContinue}
                                    onSave={onSave}
                >
                    {modal?.title ? <h3 className="font-bold text-lg"> {modal?.title}</h3> : null}
                    {modal.content}
                </SaveContinueCancelModal>)
        case "confirmCancel".toLowerCase():
            return (
                <ConfirmCancelModal visible={modal.visible}
                                    onClose={onClose}
                                    onCancel={onCancel}
                                    onConfirm={onConfirm}>
                    {modal?.title ? <h3 className="font-bold text-lg"> {modal?.title}</h3> : null}
                    {modal.content}
                </ConfirmCancelModal>)
        case "ContinueCancel".toLowerCase():
            return (
                <ContinueCancelModal visible={modal.visible}
                                    onClose={onClose}
                                    onCancel={onCancel}
                                     onContinue={onContinue}>
                    {modal?.title ? <h3 className="font-bold text-lg"> {modal?.title}</h3> : null}
                    {modal.content}
                </ContinueCancelModal>)
        case "Variable".toLowerCase():
            return (
                <VariableModal
                    variables={modal.content ? modal.content : []}
                    environments={modal.environments}
                    visible={modal.visible}
                    onClose={onClose}
                    onDelete={onDelete}
                    onChange={onChange}
                />)
        case "Script".toLowerCase():
            return (
                <ScriptModal
                    scripts={modal.content ? modal.content : []}
                    visible={modal.visible}
                    onClose={onClose}
                    onDelete={onDelete}
                    onChange={onChange}
                />)
        case "CodeEditor".toLowerCase():
            return (
                <CodeEditorModal
                    visible={modal.visible}
                    language={modal.language}
                    data={modal.content}
                    onClose={onClose}
                    onChange={onChange}/>)
        case "Input".toLowerCase():
            return (
                <InputModal
                    visible={modal.visible}
                    title={modal.title}
                    value={modal.content}
                    placeholder={modal.placeholder}
                    onClose={onClose}
                    onChange={onChange}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />)
        case "Select".toLowerCase():
            return (
                <SelectModal
                    visible={modal.visible}
                    title={modal.title}
                    value={modal.content}
                    options={modal.options}
                    onClose={onClose}
                    onChange={onChange}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />)
        default:
            return (<></>)
    }
}