import {GoPaperAirplane, GoSmiley} from "react-icons/go";
import {useEffect, useState} from "react";

//https://react-icons.github.io/react-icons/icons/go/

function ChatSendMessage(chatSendMessageData) {
    let [message, setMessage] = useState('');

    useEffect(() => {
        if (message) {
            return () => message;
        }
        setMessage('')
    }, [message]);

    return (
        <form className="ChatCapture">
            <button className={"ChatInput"}>
                <GoSmiley/>
            </button>
            <input
                className={"ChatInput"}
                placeholder={"Type a message"}
                value={message}
                onChange={(event) => captureMessage(event.target.value, setMessage)}
            />
            <button
                className={"ChatInput"}
                onClick={(event => sendMessage(event, message, setMessage, chatSendMessageData.onSendMessage))}>
                <GoPaperAirplane/>
            </button>
        </form>
    );
}

function captureMessage(messageContent, setMessage) {

    setMessage(messageContent);
}

function sendMessage(event, messageContent, setMessage, onSendMessage) {
    event.preventDefault();
    let message = {
        messageContent: messageContent,
        you: true,
    };
    onSendMessage(message);
    setMessage('');
}

export default ChatSendMessage;
