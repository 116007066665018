import * as React from "react";
import "../output.css";
import RouteBuilder from "../component/routeBuilder";
import TabBar from "../component/tabBar/TabBar";
import {useEffect, useState} from "react";
import {MapNameToElement} from "../component/MapNameToElement";
import {Drawer, Theme} from "react-daisyui";
import {ImportContextMenu} from "../component/ImportContextMenu";
import {ModalFactory} from "../component/modal/ModalFactory";
import Settings from "./settings/Settings";
import {useContentApi} from "../hooks/useRestfulApi";
import {useLocalStorage} from "../hooks/useLocalStorage";

export let openDrawer;

function getLinks(links) {
    let updatedLinks = [];
    links
        .filter(link => link.inUse)
        .forEach(
            (link) => {
                let innerLinks = getLinks(link.innerHrefs);
                updatedLinks = [...updatedLinks,
                    {
                        id: link.id,
                        href: link.href,
                        label: link.label,
                        element: MapNameToElement(link.element, link.href, innerLinks, link),
                        innerTabLinks: innerLinks,
                    }]
            });

    return updatedLinks;
}

function App() {
    const [isDark, setIsDark] = useLocalStorage('isDark', false);
    const [links, setLinks] = useState(null);
    let restfulApi = useContentApi();
    let theme = isDark ? "dark" : "light";

    let [side, setSide] = useState(<></>);
    let [isOpen, setIsOpen] = useState(false);

    openDrawer = (side, isOpen) => {
        setSide(side);
        setIsOpen(isOpen);
    };


    useEffect(() => {
        if(!links){
            restfulApi?.link.GetLink((data) => {
                let links = getLinks(data);

                setLinks([...getLinks(data),
                    {
                        id: Math.max(links.map((link) => link.id)) + 1,
                        href: "settings",
                        label: null,
                        element: <Settings/>,
                        innerTabLinks: null,
                    }])
            })
        }
            return () => links;
        },
        [links, restfulApi?.link])

    return (<Drawer side={side}
                    open={isOpen}
                    onClickOverlay={() => setIsOpen(!isOpen)}

        >
            <Theme dataTheme={theme} className={"bg-base-100 text-base-content h-screen max-h-full overflow-auto"}>
                <TabBar href={""} tabLinks={links} onChangeTheme={value => setIsDark(value)}/>
                <RouteBuilder tabLink={links} addIndex={true}/>
                <ImportContextMenu/>
                <ModalFactory/>
            </Theme>
        </Drawer>
    );
}

export default App;
