import logo from "../../assets/blue-fox logo.png";
import text from "../../assets/BFlogoTextWHT.png";
import LinkItem from "./linkItem";
import {TfiMenu, TfiSettings, TfiUser} from "react-icons/tfi";
import * as React from "react";
import {Link} from "react-router-dom";
import {IoMoonOutline, IoSunnyOutline} from "react-icons/io5";
import {Avatar} from "react-daisyui";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {openDrawer} from "../../view/App";

//https://react-icons.github.io/react-icons/icons/sl/
//https://react-icons.github.io/react-icons/icons/tfi/

function TabBar(tabBarContent) {
    const [isDark, setIsDark] = useLocalStorage('isDark', false);
    if (!tabBarContent)
        tabBarContent = {};

    let tabLinks = tabBarContent.tabLinks;
    let user = tabBarContent.user;

    if (!tabLinks)
        tabLinks = [];

    if (!user)
        user = {
            initials: "Ur",
            //profilePicture: "https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
            name: "User"
        };

    tabLinks = tabLinks.filter(row => row.label);

    tabLinks
        .filter(link => link.href.endsWith("/*"))
        .forEach(link => link.href = link.href.replace("/*", ""))

    let onChangeTheme = tabBarContent.onChangeTheme ? tabBarContent.onChangeTheme : () => {
    };

    return (
        <nav className="navbar bg-primary text-primary-content">
            <div className="navbar-start">
                <button
                    onClick={() => openDrawer(
                        <ul tabIndex="0"
                            className="menu p-4 w-80 min-h-full bg-primary">
                            {GetSmallScreenLinks(tabLinks, (tabBarContent.href ? tabBarContent.href : ""))}
                        </ul>,
                        true)} className="btn btn-ghost lg:hidden drawer-button text-xl"><TfiMenu/></button>
                <a
                    className={"btn btn-ghost flex h-0  pt-0  pb-0"}
                    href={(tabBarContent.href ? tabBarContent.href : "") +
                        (tabLinks && tabLinks.length > 0 ? tabLinks[0].href : "")}>
                    <img className={"h-full"} src={logo} alt="logo"/>
                    <img className={"h-full"} src={text} alt="logo"/>
                </a>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    {getFullScreenLinks(tabLinks, (tabBarContent.href ? tabBarContent.href : ""))}
                </ul>
            </div>
            <div className="navbar-end h-8 sm:h-10 md:h-12 lg:h-14 xl:h-16">
                <div
                    className="dropdown dropdown-hover dropdown-menu dropdown-bottom dropdown-end menu menu-vertical">
                    <div tabIndex="0" role="button" className="">
                        <Avatar
                            color={"secondary"}
                            letters={user.initials} src={user.profilePicture}
                            size="sm"
                            shape={"circle"}
                            online={false}
                        />
                    </div>
                    <ul className="dropdown-content p-2 bg-primary text-primary-content rounded-box z-[1] w-52 p-2 shadow">
                        <LinkItem
                            href={"/profile"}><TfiUser /> Profile</LinkItem>
                        <li>
                            <Link
                                className={"text-primary-content"}
                                onClick={event => {
                                    event.preventDefault();
                                onChangeTheme(!isDark);
                                setIsDark(!isDark);
                            }} to={""}>
                                <div className={"swap swap-rotate text-primary-content"}>
                                    <IoSunnyOutline className="swap-off fill-current"/>
                                    <IoMoonOutline className="swap-on fill-current"/>
                                </div>
                                <span className="text-primary-content">Theme: {isDark ? "dark" : "light"}</span>

                            </Link>
                        </li>
                        <LinkItem
                            href={"/settings"}><TfiSettings/> Settings</LinkItem>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

function getFullScreenLinks(tabLinks, baseTab) {
    let updatedBaseTab = baseTab && baseTab !== "" && !baseTab.endsWith('/')
        ? baseTab + '/' : baseTab;

    return tabLinks
        .filter(link => link.label !== null)
        .map(link => {
            if (link?.innerTabLinks?.filter(link => link.label !== null)?.length) {
                return <li key={link.id}>
                    <div
                        className="dropdown  dropdown-menu dropdown-bottom menu menu-vertical">
                        <div tabIndex="0" role="button" className="">
                            {link.label}
                        </div>
                        <ul className="dropdown-content p-2 bg-primary text-primary-content rounded-box z-[1] w-52 p-2 shadow">
                            {GetSmallScreenLinks(link.innerTabLinks, (updatedBaseTab ? updatedBaseTab : "") + link.href)}
                        </ul>
                    </div>
                </li>
            }

            return <LinkItem key={link.id}
                             href={(updatedBaseTab ? updatedBaseTab : "") + link.href}>{link.label}</LinkItem>
        });
}

export function GetSmallScreenLinks(tabLinks, baseTab) {
    let updatedBaseTab = baseTab && baseTab !== "" && !baseTab.endsWith('/')
        ? baseTab + '/' : baseTab;

    return tabLinks
        .filter(link => link.label !== null)
        .map(link => {
            if (link?.innerTabLinks?.filter(link => link.label !== null)?.length) {
                return (
                    <li key={link.id}>
                        <h2 className={"menu-title"} >{link.label}</h2>
                        <ul className="p-2 text-primary-content">
                            {GetSmallScreenLinks(link.innerTabLinks, (updatedBaseTab ? updatedBaseTab : "") + link.href)}
                        </ul>
                    </li>)
            }

            return <LinkItem key={link.id}
                             href={(updatedBaseTab ? updatedBaseTab : "") + link.href}>{link.label}</LinkItem>
        });
}

export default TabBar;
