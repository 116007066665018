import ApiDocumentation from "../view/areas/content/ApiDocumentation";
import Chat from "../view/areas/chat/Chat";
import Login from "../view/login/Login";
import MultiPage from "../view/areas/MultiPage";
import PageNotFound from "../view/pageNotFound/PageNotFound";
import Restful from "../view/tools/restful/Restful";
import * as React from "react";
import Content from "../view/areas/content/Content";
import {Home} from "../view/Home";
import OpenApiEditor from "../view/tools/OpenApiEditor";
import MarkdownEditor from "../view/tools/MarkdownEditor";
import {Faker} from "../view/tools/faker/Faker";

export function GetElementNameMap(links,parentHref) {
    return [
        {label: "MarkdownEditor", element: (elementLink) => <MarkdownEditor/>},
        {label: "OpenApiEditor", element: (elementLink) => <OpenApiEditor/>},
        {label: "ApiDocumentation", element: (elementLink) => <ApiDocumentation settings={elementLink.hrefSettings}/>},
        {label: "Chat", element: (elementLink) => <Chat/>},
        {label: "Content", element: (elementLink) => <Content id={elementLink.id}/>},
        {label: "Login", element: (elementLink) => <Login/>},
        {label: "MultiPage", element: (elementLink) => <MultiPage parentHref = {parentHref} navigationalLinks={links}/>},
        {label: "PageNotFound", element: (elementLink) => <PageNotFound/>},
        {label: "Restful", element: (elementLink) => <Restful/>},
        {label: "Home", element: (elementLink) => <Home/>},
        {label: "Faker", element: (elementLink) => (<Faker/>)},
    ];
}

export function MapNameToElement(element, parentHref, links, link) {
    let elementMap = GetElementNameMap(links,parentHref)
        .filter(elementToMap => elementToMap.label.toLowerCase() === element.toLowerCase());

    return elementMap?.length
        ? elementMap.at(0).element(link)
        : <PageNotFound/>;
}