import {Button, Input, Select} from "react-daisyui";
import {TfiAngleDown, TfiSave} from "react-icons/tfi";
import React from "react";

export function TitleBar({
                             title,
                             environment,
                             environments,
                             dropDownItems,
                             showEnvironmentSelect,
                             onChange,
                             onSave
                         }) {

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onSaveFunction = onSave ? onSave : () => {
    };

    return (
        <div className="w-full p-4 font-sans md:join">
            <Input
                className={"grow join-item w-full"}
                placeholder={"New request"}
                value={title}
                onChange={event => {
                    onChangeFunction(event.target.value, environment);
                }}
            />
            {showEnvironmentSelect ?
                <Select className={"join-item w-full md:w-32"}
                        value={environment}
                        onChange={event => {
                            onChangeFunction(title, event.target.value);
                        }}>
                    <Select.Option value={'default'} disabled>
                        Environment
                    </Select.Option>
                    {!environments ? null :
                        environments.map(environment =>
                            <Select.Option value={environment.key}> {environment.value} </Select.Option>)}
                </Select> : null
            }

            <div className="bg-secondary rounded-full md:join">
                <Button
                    className={"join-item hidden  md:block"}
                    onClick={() => onSaveFunction()} color={"secondary"}>
                    <TfiSave/>
                </ Button>
                <div className="dropdown dropdown-hover dropdown-bottom dropdown-end hidden md:block">
                    <div tabIndex="0" role="button" className="btn btn-secondary pl-2 pr-2">
                        <TfiAngleDown/>
                    </div>
                    <ul tabIndex="0"
                        className="dropdown-content menu bg-base-300 rounded-box z-[1] w-52 p-2 shadow">
                        {dropDownItems && dropDownItems.length > 0
                            ? dropDownItems.map((item, index) => <li key={index}>
                                <span onClick={item.onClick}>
                                    {item.content}
                                </span>
                            </li>)
                            : null
                        }
                    </ul>
                </div>
            </div>
        </div>)
}