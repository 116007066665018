export function Loader({children, loading, timout, onTimeout}) {

    let onTimeoutFunction = onTimeout ? onTimeout : () => {
    }

    new Promise(res => setTimeout(onTimeoutFunction(), timout ? timout : 100))

    return (<>
            {!loading ? children :
                <div className={"grid max-w-full w-full max-h-full h-full place-items-center"}>
                    <span className={"flex gap-2 content-center"}>
                        <span className={"text-7xl"}>
                            Loading
                        </span>
                    <span className="loading loading-dots w-20"></span>
                    </span>
                </div>}
        </>
    );
}
