import React from "react";
import {GoPaperAirplane} from "react-icons/go";
import {Button, Input, Select} from "react-daisyui";

export function RestCaptureBar({httpmethod, endpoint, resource, disabled, onChange, onSend}) {
    let items = [
        {key: 1, displayValue: "GET", value: "GET"},
        {key: 2, displayValue: "POST", value: "POST"},
        {key: 3, displayValue: "PUT", value: "PUT"},
        {key: 4, displayValue: "PATCH", value: "PATCH"},
        {key: 5, displayValue: "DELETE", value: "DELETE"},
        {key: 6, displayValue: "COPY", value: "COPY"},
        {key: 7, displayValue: "HEAD", value: "HEAD"},
        {key: 8, displayValue: "OPTIONS", value: "OPTIONS"},
        {key: 9, displayValue: "LINK", value: "LINK"},
        {key: 10, displayValue: "UNLINK", value: "UNLINK"},
        {key: 11, displayValue: "PURGE", value: "PURGE"},
        {key: 12, displayValue: "LOCK", value: "LOCK"},
        {key: 13, displayValue: "PROPFIND", value: "PROPFIND"},
        {key: 14, displayValue: "VIEW", value: "VIEW"},
    ];

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onSendFunction = onSend ? onSend : () => {
    };

    return (
        <div className="pl-4 pr-4 w-full font-sans block md:join">
            <Select
                disabled={disabled}
                className={"w-full join-item hidden md:block md:max-w-32"}
                value={
                    items?.filter(iteam => iteam.value === httpmethod)?.length
                        ? items.filter(iteam => iteam.value === httpmethod)?.at(0)?.displayValue
                        :items?.at(0)?.displayValue

            }
                onChange={event => onChangeFunction(event.target.value, endpoint, resource)}>
                <Select.Option value={'default'} disabled>
                    Http Method
                </Select.Option>
                {items.map(option => <Select.Option key={option.key}>{option.displayValue}</Select.Option>)}
            </Select>
            <Input
                disabled={disabled}
                className={"w-full join-item hidden md:block md:max-w-56"}
                placeholder={"Endpoint"}
                value={endpoint ? endpoint : ""}
                onChange={event => onChangeFunction(httpmethod, event.target.value, resource)}/>

            <Input
                disabled={disabled}
                className={"w-full join-item hidden md:block "}
                placeholder={"Endpoint Resource"}
                value={resource ? resource : ""}
                onChange={event => onChangeFunction(httpmethod, endpoint, event.target.value)}/>

            <Button
                disabled={disabled}
                className={"w-full join-item md:max-w-20"}
                color={"accent"}
                onClick={event => {
                    event.preventDefault();
                    onSendFunction();
                }}>
                <GoPaperAirplane/>
            </ Button>
        </div>
    );
}

