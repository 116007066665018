import React from "react";
import CodeEditor from "../CodeEditor";
import {Modal} from "./Modal";

export function CodeEditorModal({children, data, language, visible, onClose, onChange}) {

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onCloseFunction = onClose ? onClose : () => {
    };

    return (<Modal
        onClose={onClose}
        visible={visible}
        isLarge={true}
    >
        <div className="h-[90%] overflow-hidden">
            <CodeEditor
                language={language}
                data={data}
                onChange={onChangeFunction}>
                {children}
            </CodeEditor>
        </div>
        <div className="modal-action">
            <button className="btn btn-error" onClick={() => {
                onCloseFunction();
            }}>
                Close
            </button>
        </div>
    </Modal>);
}