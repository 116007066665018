import {useEffect, useRef} from "react";

export function Modal({children, visible, isLarge, onClose}) {
    const modalRef = useRef(null);

    let onCloseFunction = onClose ? onClose : () => {
    };

    useEffect(() => {
        if (!modalRef.current) {
            return;
        }
        visible ? modalRef.current.showModal() : modalRef.current.close();
    }, [visible]);

    const handleESC = (event) => {
        event.preventDefault();
        onCloseFunction();
    }

    return (
        <dialog ref={modalRef} id={"ConfirmCancelModal"} className="modal" onCancel={handleESC}>
            {isLarge ?
                <form method="dialog" className="modal-box w-11/12 max-w-[90vw] h-[90%]">
                    {children}
                </form> :
                <form method="dialog" className="modal-box">
                    {children}
                </form>}
        </dialog>
    );
}