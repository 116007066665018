import React, {useState} from "react";
import {Select} from "react-daisyui";
import {ConfirmCancelModal} from "./ConfirmCancelModal";

export function SelectModal({visible, options, title, value, onChange, onClose, onCancel, onConfirm}) {

    let [inputValue, setInputValue] = useState(value ? value.toString() : "");

    let onChangeFunction = onChange ? onChange : () => {
    };
    let onConfirmFunction = onConfirm ? onConfirm : () => {
    };

    return (<ConfirmCancelModal visible={visible}
                                onClose={onClose}
                                onCancel={onCancel}
                                onConfirm={() => {
                                    onConfirmFunction(inputValue);
                                }}>
            {title ? <h3 className="font-bold text-lg"> {title}</h3> : null}
            <Select
                className={"w-full"}
                onChange={(event) => {
                    setInputValue(event.target.value);
                    onChangeFunction(event.target.value);
                }}
                value={inputValue}
            >
                {options?.map((option, index) => <Select.Option key={index}
                                                                value={option.key}>{option.value}</Select.Option>)}
            </Select>
        </ConfirmCancelModal>
    );
}