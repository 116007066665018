import React, {useEffect} from "react";
import StyledSwaggerUI from "../../component/openApi/StyledSwaggerUI";
import CodeEditor from "../../component/CodeEditor";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {Tabs} from "react-daisyui";
import {StyledMarkdown} from "../../component/markdown/StyledMarkdown";

export default function OpenApiEditor() {
    let [doc, setDoc] = useLocalStorage("OpenApiEditor")
    let [showInput, setShoInput] = useLocalStorage('showInputOpenApiEditor', false);

    useEffect(()=>{
        if(!doc){
            setDoc({})
        }

        return () =>doc;
    },[doc,setDoc])

    return (

        <div className="w-full">
            <Tabs variant={"bordered"} className={"md:hidden"}>
                <Tabs.Tab active={showInput}
                          onClick={() => setShoInput(true)}>
                    Code Editor
                </Tabs.Tab>
                <Tabs.Tab active={!showInput}
                          onClick={() => setShoInput(false)}>
                    Markdown preview
                </Tabs.Tab>
            </Tabs>
            <div className="flex p-2">
                <div
                    className={"h-[90] max-h-[89vh] w-full md:max-w-[50vw] min-w-[25vw] overflow-hidden hover:resize-x md:block "
                        + (showInput ? "block" : "hidden")}>
                    <CodeEditor
                        language={"json"}
                        data={doc}
                        onChange={(doc) => setDoc(doc)}>
                        <></>
                    </CodeEditor>
                </div>
                <div className={"max-h-[89vh]  h-[89vh]  w-full md:max-w-[50vw]  m-auto overflow-auto  md:block "
                    + (!showInput ? "block" : "hidden")}>
                    <StyledSwaggerUI spec={doc}/>
                </div>
            </div>
        </div>
)
    ;
}