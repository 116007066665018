import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const restfulDetailUrl = "/RestfulDetail/v1/";
const newRestfulDetailUrl = restfulDetailUrl + "new";

export function RestfulDetailController(endpoint) {
    return {
        GetNewRestfulDetail: (collectionId, folderId, setData) => GetNewRestfulDetail(endpoint, collectionId, folderId, setData),
        GetRestfulDetail: (id, setData) => GetRestfulDetail(endpoint, id, setData),
        PostRestfulDetail: (restfulDetail, setData) => PostRestfulDetail(endpoint, restfulDetail, setData),
        PutRestfulDetail: (restfulDetail, setData) => PutRestfulDetail(endpoint, restfulDetail, setData),
        DeleteRestfulDetail: (restfulDetail, setData) => DeleteRestfulDetail(endpoint, restfulDetail, setData),
    }
}

function GetNewRestfulDetail(endpoint, collectionId, folderId, setData) {
    let rest = restGet(endpoint, newRestfulDetailUrl);
    rest(new Map([["collectionId", collectionId.toString()], ["folderId", folderId.toString()]]), [], setData);
}

function GetRestfulDetail(endpoint, id, setData) {
    let rest = restGet(endpoint, restfulDetailUrl);
    rest(new Map([["id", id]]), [], setData);
}

function PostRestfulDetail(endpoint, restfulDetail, setData) {
    let rest = restPost(endpoint, restfulDetailUrl);
    rest(restfulDetail, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutRestfulDetail(endpoint, restfulDetail, setData) {
    let rest = restPut(endpoint, restfulDetailUrl);
    rest(restfulDetail, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteRestfulDetail(endpoint, restfulDetail, setData) {
    let rest = restDelete(endpoint, restfulDetailUrl);
    rest(restfulDetail, [], new Map([["Content-Type", "application/json"]]), setData);
}