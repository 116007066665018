function LoadingPage({show}) {
    let className = show ? "loading-overlay active" : "loading-overlay";

    return (
        <div className={className}>
            <div className="loader"/>
        </div>
    );
}

export default LoadingPage;