import {TfiTrash} from "react-icons/tfi";
import React, {useEffect, useState} from "react";
import Authorization from "../../../component/restful/Authorization";
import CodeEditor from "../../../component/CodeEditor";
import OptionSection from "../../../component/restful/OptionSection";
import {HeaderParameters, QueryParameters} from "../../../component/restful/QueryParameters";
import {Button} from "react-daisyui";
import {Loader} from "../../../component/Loader";
import {TbVariable} from "react-icons/tb";
import {HandleOpenModal} from "../../../component/modal/ModalFactory";
import {TitleBar} from "../../../component/restful/TitleBar";
import {RestCaptureBar} from "../../../component/restful/RestCaptureBar";
import {useRestfulApi} from "../../../hooks/useRestfulApi";
import {RiJavascriptFill} from "react-icons/ri";
import {Variables} from "../../../component/restful/Variables";

export function RestRequest({tabKey, id, data, onChange, onDelete, onSave}) {

    let [currentTab, setCurrentTab] = useState(data);
    let restfulApi = useRestfulApi();

    let [environments, setEnvironments] = useState(data?.environments);
    let [title, setTitle] = useState(data && data.label !== "Untitled Request"
        ? data.label : "");
    let [endpoint, setEndpoint] = useState(data?.endpoint ? data.endpoint : "");
    let [httpMethod, setHttpMethod] = useState(data?.httpmethod ? data.httpmethod : "");
    let [resource, setResource] = useState(data?.resource ? data.resource : "");
    let [environment, setEnvironment] = useState(data?.environment ? data.environment : null);

    let [responseContent, setResponseContent] = useState(data?.response?.data
        ? data.response.data
        : {});
    let [requestContent, setRequestContent] = useState(data?.request?.data ? data.request.data : {});
    let [response, setResponse] = useState({});

    useEffect(() => {
        if (!currentTab) {
            restfulApi?.restfulDetail?.GetRestfulDetail(id, (data => {

                setEndpoint(data?.endpoint ? data.endpoint : "");
                setHttpMethod(data?.httpmethod ? data.httpmethod : "");
                setResource(data?.resource ? data.resource : "");
                setEnvironment(data?.environment ? data.environment : "");

                setTitle(data && data.label !== "Untitled Request"
                    ? data.label : "");


                setRequestContent(data?.request?.data ? data.request.data : {})

                restfulApi?.environment.GetEnvironment((environmentData) => {
                    data.environments = environmentData.map((environment) => {
                        return {
                            key: environment.id,
                            value: environment.name,
                        }
                    });

                    setCurrentTab(data);
                    setEnvironments(data.environments);
                });
            }));


        }


        return () => currentTab;
    }, [currentTab,id,restfulApi?.environment,restfulApi?.restfulDetail]);


    let [requestOption, setRequestOption] = useState("Params");
    let [responseOption, setResponseOption] = useState("Body");

    let [language, setLanguage] = useState("json");
    let [contentType, setContentType] = useState("application/json");

    let [loadData, setLoadData] = useState(false);
    let [refreshPage, setRefreshPage] = useState(false);

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onSaveFunction = onSave ? onSave : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };

    let requestOptions = getRequestOptions(currentTab, onChangeFunction, setCurrentTab, requestContent, setRequestContent);

    let responseOptions = [
        {
            id: 1, label: "Body", element:
                <CodeEditor
                    language={language}
                    onChange={(content) => {
                        response.data = content;
                        currentTab.response = response;
                        setResponseContent(content);
                        setResponse(response);
                        setCurrentTab(currentTab)
                        onChangeFunction(currentTab)
                    }}
                    data={
                        responseContent
                            ? responseContent
                            : (currentTab?.response?.data ? currentTab.response.data : {})}>
                    <div className={"lg:flex lg:gap-24 "}>
                        <Button size={"xs"}
                                onClick={() => HandleOpenModal({
                                    type: 'CodeEditor',
                                    onChange: (content) => {
                                        response.data = content;
                                        currentTab.response = response;
                                        setResponseContent(content);
                                        setResponse(response);
                                        setCurrentTab(currentTab)
                                        onChangeFunction(currentTab)
                                    },
                                    language: language,
                                    content: responseContent
                                        ? responseContent
                                        : (currentTab && currentTab.response && currentTab.response.data ? currentTab.response.data : {}),
                                })}
                        >
                            Expand response
                        </Button>
                        <p>Http status: <span
                            className={"font-bold " + (response && response.status && response.status.includes("200") ? "text-success" : "text-error")}>
                                {response && response.status ? response.status : "..."}</span></p>
                        <p>Time take to respond: <span
                            className={"font-bold"}>{response && response.timeTaken ? response.timeTaken : "..."}</span>
                            ms
                        </p>
                        <p>content-type: <span className={"font-bold"}>{contentType ? contentType : "..."}</span></p>
                    </div>
                </CodeEditor>
        },
        {
            id: 2, label: "Headers", element: <HeaderParameters
                onChange={(params) => {
                    if (currentTab.response) {
                        currentTab.response.headers = params;
                        setCurrentTab(currentTab);
                    }
                }}
                data={response?.headers ? response.headers : []}/>
        },
        {
            id: 2, label: "Variables", element: <Variables
                environments={environments}
                data={response?.variables ? response.variables : []}
                onDelete={() => onDeleteFunction()}
            />
        },
    ]

    return (
        <Loader loading={!currentTab}>

            <div className={"w-full md:max-w-[80vw]"}>
                <Loader loading={refreshPage}>
                    <TitleBar
                        title={title}
                        environment={environment}
                        environments={environments}
                        showEnvironmentSelect={true}
                        onSave={() => {
                            setRefreshPage(true);
                            restfulApi?.restfulDetail?.PutRestfulDetail(currentTab, (data) => {

                                if (!data.id) {
                                    setRefreshPage(false);
                                    return;
                                }

                                setRefreshPage(false);
                                onSaveFunction(data);
                                setCurrentTab(data);
                            })
                        }}
                        onChange={(title, environment) => {
                            currentTab.label = title;
                            currentTab.environment = Number(environment);
                            setTitle(title);
                            setCurrentTab(currentTab);
                            setEnvironment(currentTab.environment)
                            onChangeFunction(currentTab);
                        }}
                        dropDownItems={getNamBarOptions(restfulApi, environments, currentTab, onChangeFunction, onDeleteFunction, setRefreshPage)}
                    />

                    <RestCaptureBar
                        endpoint={endpoint}
                        httpmethod={httpMethod}
                        resource={resource}
                        disabled={loadData}
                        onSend={() => {
                            setLoadData(true);
                            restfulApi?.request?.PostRequest(
                                currentTab,
                                (data) => {
                                    if (!(data?.response?.headers?.filter(item => item.key.toLowerCase() === "content-type")?.length)) {
                                        setResponseContent(data.response.data);
                                        setLoadData(false);
                                        setLanguage("Text");
                                        setContentType("application/text");
                                    }
                                    let contentType = data.response.headers
                                        .filter(item => item.key.toLowerCase() === "content-type")
                                        .at(0).value;

                                    setLanguage(contentType
                                        .split(" ").at(0).trim().replace(';', '')
                                        .split("/").at(1));

                                    setContentType(contentType);
                                    setResponseContent(data.response.data);
                                    data.response.variables?.forEach((variable, index) => variable.id = index);

                                    setResponse(data.response);
                                    currentTab.response = data.response;
                                    setCurrentTab(currentTab);

                                    onChangeFunction(currentTab);
                                    setLoadData(false);
                                });
                        }}
                        onChange={(httpmethod, endpoint, resource) => {
                            if (!title || title === currentTab.resource) {
                                currentTab.label = resource;
                                setTitle(resource);
                            }

                            currentTab.httpmethod = httpmethod;
                            currentTab.endpoint = endpoint;
                            currentTab.resource = resource;

                            setHttpMethod(currentTab.httpmethod);
                            setEndpoint(currentTab.endpoint);
                            setResource(currentTab.resource);

                            setCurrentTab(currentTab);
                            onChangeFunction(currentTab);
                        }}
                    />
                    <span className={"hidden md:block"}>
                        <OptionSection
                            data={currentTab && currentTab.request && currentTab.request.data ? currentTab.request.data : {}}
                            className={"my-2 mx-3 h-[60vh] md:h-[28vh] w-full max-w-full overflow-scroll overflow-x-auto overflow-y-auto"}
                            tabId={tabKey}
                            responseOptions={requestOptions}
                            onChange={setRequestOption}
                            page={requestOption}
                        />
                    </span>
                    <Loader loading={loadData}>
                        <OptionSection
                            data={responseContent && responseContent ? responseContent : {}}
                            tabId={tabKey}
                            className={"my-2 mx-3 h-[60vh] md:h-[32vh] w-full max-w-full overflow-scroll overflow-x-auto overflow-y-auto"}
                            responseOptions={responseOptions}
                            page={responseOption}
                            onChange={setResponseOption}
                        />
                    </Loader>
                </Loader>
            </div>
        </Loader>
    );
}

function getRequestOptions(currentTab, onChange, setCurrentTab, requestContent, setRequestContent) {
    return [
        getBodyRequestOption(3,
            true,
            currentTab,
            onChange,
            setCurrentTab,
            requestContent,
            setRequestContent),
        getQueryParametersRequestOption(0, currentTab, onChange, setCurrentTab),
        {id: 1, label: "Authorization", element: <Authorization/>},
        getHeaderParametersRequestOption(2, currentTab, onChange, setCurrentTab)];
}

function getQueryParametersRequestOption(id, currentTab, onchange, setCurrentTab) {
    return {
        id: id, label: "Params", element:
            <QueryParameters
                onChange={(params) => {
                    if (currentTab && currentTab.request) {
                        currentTab.request.queryParameters = params;
                    }
                    setCurrentTab(currentTab);
                    onchange(currentTab);
                }}
                data={currentTab && currentTab.request && currentTab.request.queryParameters ? currentTab.request.queryParameters : []}/>
    };
}

function getHeaderParametersRequestOption(id, currentTab, onchange, setCurrentTab) {
    return {
        id: id, label: "Headers", element:
            <HeaderParameters
                onChange={(params) => {
                    if (currentTab && currentTab.request) {
                        currentTab.request.headers = params;
                    }
                    setCurrentTab(currentTab);
                    onchange(currentTab);
                }}
                data={currentTab && currentTab.request && currentTab.request.headers ? currentTab.request.headers : []}/>
    };
}

function getBodyRequestOption(id, editable, currentTab, onChange, setCurrentTab, requestContent, setRequestContent) {
    function onCodeChange(data) {
        currentTab.request = !currentTab.request ? {} : currentTab.request;
        currentTab.request.data = data
        setCurrentTab(currentTab);
        onChange(currentTab);
        setRequestContent(data);
    }

    let language = "json";
    return {
        id: id, label: "Body", element:

            <CodeEditor
                language={language}
                data={requestContent}
                onChange={onCodeChange}>
                <Button size={"xs"}
                        onClick={() => HandleOpenModal({
                            type: 'CodeEditor',
                            onChange: onCodeChange,
                            language: language,
                            content: requestContent,
                        })}
                >
                    Expand request
                </Button>
            </CodeEditor>
    };
}

function getNamBarOptions(restfulApi, environments, currentTab, onChange, onDelete, setRefreshPage) {
    return [
        {
            onClick: () => HandleOpenModal({
                type: 'variable',
                environments: (environments ? environments : []),
                onDelete: () => {
                    setRefreshPage(true);
                    new Promise(res => setTimeout(setRefreshPage(false), 1000));
                },
                onChange: (variables) => {
                    if (currentTab?.request?.variables) {
                        currentTab.request.variables = variables;
                    }
                    onChange(currentTab);
                },
                content: currentTab?.request?.variables ? currentTab.request.variables : null,
            }),
            content: <><TbVariable/> Variables</>,
        },
        {
            onClick: () => HandleOpenModal({
                type: 'script',
                environments: (environments ? environments : []),
                onDelete: () => {
                    setRefreshPage(true);
                    new Promise(res => setTimeout(setRefreshPage(false), 1000));
                },
                onChange: (preScripts) => {
                    if (currentTab?.request?.preScripts) {
                        currentTab.request.preScripts = preScripts;
                    }
                    onChange(currentTab);
                },
                content: currentTab?.request?.preScripts ? currentTab.request.preScripts : null,
            }),
            content: <><RiJavascriptFill/> Pre-request script</>,
        },
        {
            onClick: () => HandleOpenModal({
                type: 'script',
                environments: (environments ? environments : []),
                onDelete: () => {
                    setRefreshPage(true);
                    new Promise(res => setTimeout(setRefreshPage(false), 1000));
                },
                onChange: (postScripts) => {
                    if (currentTab?.request?.postScripts) {
                        currentTab.request.postScripts = postScripts;
                    }
                    onChange(currentTab);
                },
                content: currentTab?.request?.postScripts ? currentTab.request.postScripts : null,
            }),
            content: <><RiJavascriptFill/> Post-request script</>,
        },
        {
            onClick: () => HandleOpenModal({
                title: 'Delete request',
                type: 'confirmCancel',
                onConfirm: () => {
                    restfulApi?.restfulDetail?.DeleteRestfulDetail(currentTab, (data) => onDelete(data));
                },
                content: <>
                    <p className="pt-4">You are about to delete this request.</p>
                    <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
                </>,
            }),
            content: <><TfiTrash/> Delete</>,
        }
    ];
}