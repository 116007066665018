import io from 'socket.io-client';

export function getSocket() {
    // Create a WebSocket connection to your server
    //use wss for secure websocket
    const socket = io('http://localhost:30005/messaging/websocket',{
        path: "/messaging/socket.io"
    }); // Replace with your actual backend URL

    // Event listeners for WebSocket events
    socket.on('connect', () => {
        console.log('WebSocket connected');
    });

    socket.on('/hello', () => {
        console.log('WebSocket connected hello');
    });

    socket.on('/topic', () => {
        console.log('WebSocket connected topic');
    });

    socket.on('/message', () => {
        console.log('WebSocket connected topic');
    });

    socket.on('/topic/message', () => {
        console.log('WebSocket connected /topic/message');
    });

    socket.on('disconnect', () => {
        console.log('WebSocket disconnected');
    });


    socket.emit('/topic/message','hello')

    return socket;
}