import * as React from "react";
import {TbError404} from "react-icons/tb";

export default function PageNotFound() {
    return (
        <div className="grid max-w-full w-full max-h-[90vh] h-[90vh] place-items-center">
            <div className={"text-[30rem] text-base-content"}>
                <TbError404 />
            </div>
            <div className={"text-[5rem] text-base-content"}>
                Page Not Found!
            </div>
        </div>
    );
}
