import logo from "../assets/blue-fox logo.png";
import text from "../assets/BFlogoText.png";
import testTogether from "../assets/test together.png";
import React from 'react'

export function Home() {
    return (
        <div className={"bg-base-100"}>
            <section className={"pt-10 sm:pt-2 md:pt-18"}>
                <div className={"mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0 lg:flex"}>
                    <div className={"m-auto"}>
                        <div className={"bg-primary justify-center items-center rounded-full " +
                            "w-40 h-40 md:w-60 md:h-60 lg:w-80 lg:h-80 flex m-auto"}>
                            <img className={"m-auto w-44 h-40 md:w-56 md:h-60 lg:w-72 lg:h-80 "
                            } src={logo} alt="logo"/>
                        </div>
                    </div>
                    <div className={"m-auto"}>
                        <div className={"justify-center items-center h-40 flex m-auto"}>
                            <img className={"m-auto h-full "
                            } src={text} alt="logo"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"md:flex"}>
                    <div className={"mx-auto max-w-[80vw] md:max-w-[65vw]"}>
                        <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>
                            Where we test together</h2>
                        <img className={"mx-auto block md:hidden"} src={testTogether} alt="test Together"/>
                        <p className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            Test your internal and external Api's with the Blue-Fox Solutions toolkit.
                            <br/>
                            We Aim to help you build, test and monitor your Api's quicker and better.
                        </p>
                    </div>
                    <div className={"mx-auto hidden md:block"}>
                        <img className={"m-auto max-w-[20vw]"} src={testTogether} alt="test Together"/>
                    </div>
                </div>
            </section>
        </div>
    );
}