import {useEffect, useRef} from "react";

export function ContinueCancelModal({children, visible, onClose, onCancel, onContinue}) {
    const modalRef = useRef(null);

    let onCloseFunction = onClose
        ? onClose
        : () => {
        };
    let onCancelFunction = onCancel
        ? onCancel
        : () => {
        };
    let onContinueFunction = onContinue
        ? onContinue
        : () => {
        };

    useEffect(() => {
        if (!modalRef.current) {
            return;
        }
        visible ? modalRef.current.showModal() : modalRef.current.close();
    }, [visible]);

    const handleESC = (event) => {
        event.preventDefault();
        onCloseFunction();
    }

    return (
        <dialog ref={modalRef} id={"ContinueCancelModal"} className="modal" onCancel={handleESC}>
            <form method="dialog" className="modal-box">
                {children}
                <div className="modal-action">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="btn btn-success text-success-content" onClick={() => {
                        onContinueFunction();
                        onCloseFunction();
                    }}>
                        Continue
                    </button>
                    <button className="btn btn-error text-error-content" onClick={() => {
                        onCancelFunction();
                        onCloseFunction();
                    }}>
                        Cancel
                    </button>
                </div>
            </form>
        </dialog>
    );
}