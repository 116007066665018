import '../../../component/markdown/github-dark.css'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import React, {useEffect, useState} from 'react'
import LoadingPage from "../../../component/LoadingPage";
import {useContentApi} from "../../../hooks/useRestfulApi";
import {StyledMarkdown} from "../../../component/markdown/StyledMarkdown";

//https://github.com/remarkjs/react-markdown/blob/main/readme.md

function Content({id}) {
    const [page, setPage] = useState(
        {
            pageContent: {
                content: ""
            }
        }
    );
    const contentApi = useContentApi();

    useEffect(() => {
        if (page) {
            contentApi?.page.GetPage(id, setPage);
        }

    }, [contentApi?.page, id, page])

    return (
        <div className={"p-2 max-h-[89vh] m-auto max-w-[80vw] overflow-auto"}>

            <LoadingPage show={!page}>
                <StyledMarkdown
                    content={page?.pageContent?.content}
                />
            </LoadingPage>
        </div>
    )
        ;
}



export default Content;