import React, {useEffect, useRef} from 'react';
import ChatBubble from "./ChatBubble";

function ChatDisplay (chatDisplay) {
    const chatContainerRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom when messages update
        chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [chatDisplay.messages]);

    return (
        <div className="ChatContent" >
            {
                chatDisplay.messages.map(message => (
                    <ChatBubble key={message.id} message={message.messageContent} isYou={message.you}/>
                ))
            }
            <div ref={chatContainerRef}></div>
        </div>
    );
}

export default ChatDisplay;