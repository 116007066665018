import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const environmentUrl = "/Environment/v1/";

export function EnvironmentController(endpoint) {
    return {
        GetEnvironment: (setData) => GetEnvironment(endpoint, setData),
        PostEnvironment: (environments, setData) => PostEnvironment(endpoint, environments, setData),
        PutEnvironment: (environments, setData) => PutEnvironment(endpoint, environments, setData),
        DeleteEnvironment: (environment, setData) => DeleteEnvironment(endpoint, environment, setData),
    }
}

function GetEnvironment(endpoint, setData) {
    let rest = restGet(endpoint, environmentUrl);
    rest([], [], setData);
}

function PostEnvironment(endpoint, environments, setData) {
    let rest = restPost(endpoint, environmentUrl);
    rest(environments, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutEnvironment(endpoint, environments, setData) {
    let rest = restPut(endpoint, environmentUrl);
    rest(environments, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteEnvironment(endpoint, environment, setData) {
    let rest = restDelete(endpoint, environmentUrl);
    rest(environment, [], new Map([["Content-Type", "application/json"]]), setData);
}