import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const variableUrl = "/Variable/v1/";

export function VariableController(endpoint) {
    return {
        GetVariables: (setData) => GetVariables(endpoint, setData),
        PostVariables: (variables, setData) => PostVariables(endpoint, variables, setData),
        PutVariables: (variables, setData) => PutVariables(endpoint, variables, setData),
        DeleteVariables: (variable, setData) => DeleteVariables(endpoint, variable, setData),
    }
}

function GetVariables(endpoint, setData) {
    let rest = restGet(endpoint, variableUrl);
    rest([], [], setData);
}

function PostVariables(endpoint, variables, setData) {
    let rest = restPost(endpoint, variableUrl);
    rest(variables, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutVariables(endpoint, variables, setData) {
    let rest = restPut(endpoint, variableUrl);
    rest(variables, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteVariables(endpoint, variable, setData) {
    let rest = restDelete(endpoint, variableUrl);
    rest(variable, [], new Map([["Content-Type", "application/json"]]), setData);
}

