import {TfiPencilAlt, TfiTrash} from "react-icons/tfi";
import React from "react";
import {HandleOnContext} from "../ImportContextMenu";
import {HandleOpenModal} from "../modal/ModalFactory";
import {IoDuplicateOutline} from "react-icons/io5";
import {IoMdMove} from "react-icons/io";
import {RiFolderSharedLine} from "react-icons/ri";
import {BsCollection} from "react-icons/bs";
import {useRestfulApi} from "../../hooks/useRestfulApi";

export function RestRequestListItem({
                                        collectionId,
                                        collections,
                                        filter,
                                        restfulDetails,
                                        onSelectRequest,
                                        onGetRequest,
                                        onPutRequest,
                                        onUpdate,
                                        onDuplicateRequest,
                                        onUpdateNameRequest,
                                        onDeleteRequest
                                    }) {

    let onSelectRequestFunction = onSelectRequest ? onSelectRequest : () => {
    };

    return (
        <>
            {(restfulDetails ? restfulDetails : [])
                .filter(row => row && row.label && row.label.toLowerCase()
                    .includes(filter))
                .map(restDetail =>
                    <li key={restDetail.id}
                        onClick={() => {
                            onSelectRequestFunction(collectionId, restDetail);
                        }}
                        onContextMenu={event => HandleOnContext(event, restDetail.id,
                            getContextButtons(onDeleteRequest, onUpdateNameRequest, onGetRequest, onDuplicateRequest, onPutRequest, collections, restDetail, onUpdate))}>
                        <a className={"flex overflow-clip"}
                           data-tip={restDetail.label}>
                            <p className={"truncate ... md:max-w-[10rem]"}>
                                {restDetail.httpmethod + ": " + restDetail.label}
                            </p>
                        </a>
                    </li>
                )}
        </>
    );
}


function getContextButtons(onDeleteRequest, onUpdateNameRequest, onGetRequest, onDuplicateRequest, onPutRequest, collections, restDetail, onUpdate) {

    let onUpdateFunction = onUpdate ? onUpdate : () => {
    };

    return [
        {
            content: <><IoDuplicateOutline/> Duplicate</>,
            onClick: (restDetailId) => {
                onGetRequest(restDetailId, (data) => {
                    HandleOpenModal({
                        title: 'Rename Duplicate',
                        type: 'Input',
                        content: data.label,
                        onConfirm: (value) => onDuplicateRequest(data, (duplicate) => onUpdateNameRequest(duplicate.id, value, onUpdateFunction)),
                    });
                });
            },
        },
        {
            content: <><IoMdMove/>Move</>,
            isGroup: true,
            buttons: [
                {
                    content: <><BsCollection/> Collection</>,
                    onClick: (restDetailId) => {
                        HandleOpenModal({
                            title: 'Move Collection',
                            type: 'Select',
                            options: collections.map(collection => {
                                return {key: collection.id, value: collection.name}
                            }),
                            onConfirm: (value) => {
                                if (restDetail.collectionId === value) {
                                    return;
                                }

                                onGetRequest(restDetailId, (data) => {
                                    data.collectionId = Number(value) ;
                                    data.folderId = null;
                                    onPutRequest(data, onUpdateFunction);
                                });
                            },
                            content: restDetail.collectionId,
                        })
                    },
                },
                {
                    content: <><RiFolderSharedLine/> Folder</>,
                    onClick: (restDetailId) => {
                            function getFolders(folders) {
                                let folderData = folders?.flatMap(folder => folder.folders);

                                if (folderData?.flatMap(folder => folder.folders).length) {
                                    return [...folderData, ...getFolders(folderData)];
                                }
                                return folderData;
                            }

                            let folders = getFolders(collections.filter(collection => collection.id === restDetail.collectionId))

                            HandleOpenModal({
                                title: 'Move Folder',
                                type: 'Select',
                                options: folders.map(folder => {
                                    return {key: folder.id, value: folder.name}
                                }),
                                onConfirm: (value) => {
                                    if (restDetail.folderId === value) {
                                        return;
                                    }
                                    onGetRequest(restDetailId, (data) => {
                                        data.folderId = Number(value);
                                        onPutRequest(data, onUpdateFunction);
                                    });
                                },
                                content: restDetail?.folderId ? restDetail.folderId : folders?.at(0)?.id,
                            });
                    },
                }
            ]
        },
        {
            content: <><TfiPencilAlt/> Maintain</>,
            isGroup: true,
            buttons: [
                {
                    content: <><TfiPencilAlt/> Rename</>,
                    onClick: () => HandleOpenModal({
                        title: 'Rename request',
                        type: 'Input',
                        content: restDetail.label,
                        onConfirm: (value) => onUpdateNameRequest(restDetail.id, value, onUpdateFunction),
                    }),
                },
                {
                    content: <><TfiTrash/> Delete</>,
                    onClick: () => HandleOpenModal({
                        title: 'Delete request',
                        type: 'confirmCancel',
                        onConfirm: () => {
                            onDeleteRequest(restDetail, (data) => onUpdateFunction(data));
                        },
                        content: <>
                            <p className="pt-4">You are about to delete this request.</p>
                            <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
                        </>,
                    })
                }
            ]
        },
    ]
}