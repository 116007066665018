import * as React from "react";
import {Params} from "./Params";

export function QueryParameters({onChange,data}) {
    return (
        <div>
            <h1>Query Parameters</h1>
            <Params
                onChange={onChange}
                data={data}/>
        </div>);
}

export function HeaderParameters({onChange,data}) {
    return (
        <div>
            <h1>Header Parameters</h1>
            <Params
                onChange={onChange}
                data={data}/>
        </div>);
}