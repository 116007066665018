
function ChatContacts(contactData) {
    return (
        <div className="ChatContacts">
            {
                contactData.contacts.map(contact => (
                    <div key={contact.id} className={"contact"}>
                        <img src={contact.avatar} alt={"logo"}/>
                        <div>
                            <p className={"contactName"}>{contact.name}</p>
                            <p className={"LastMessage"}>{contact.lastMessage}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default ChatContacts;