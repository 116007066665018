import React, {useEffect, useState} from "react";
import ChatDisplay from "../../../component/chat/ChatDisplay";
import ChatContacts from "../../../component/chat/ChatContacts";
import ChatSendMessage from "../../../component/chat/ChatSendMessage";
import {getSocket} from "../../../api/WebsocketConnectorjs";
import {useMessagingApi} from "../../../hooks/useRestfulApi";

//https://react-icons.github.io/react-icons/icons/go/

function Chat() {
    let [contacts, setContacts] = useState([]);
    let [messages, setMessages] = useState([]);
    let [socket] = useState(getSocket());
    let messagingApi = useMessagingApi();

    useEffect(() => {
        if (contacts) {
            return () => contacts;
        }
        setContacts([])
    }, [contacts]);

    useEffect(() => {
        if (messages) {
            return () => messages;
        }
        setMessages([])
    }, [messages]);

    useEffect(() => {

    }, [socket]);


    return (
        <div className="Chat">
            <ChatContacts contacts={contacts}/>
            <div className="ChatMainWindow">
                <ChatDisplay messages={messages}/>
                <ChatSendMessage messages={messages}
                                 onSendMessage={(message) => sendMessage(messagingApi,socket, message, messages, setMessages)}/>
            </div>
        </div>
    );
}

function sendMessage(messagingApi,socket, message, messages, setMessages) {

    let oldMessage = messages.at(messages.length - 1);
    if (oldMessage)
        message.id = oldMessage.id + 1;

    socket.emit('message', 'Hello from the client!');
    messagingApi?.messaging?.PostMessage(message, (messageData) => setMessages([...messages, messageData]))
}

export default Chat;
