import * as React from 'react'
import {useEffect, useRef, useState} from "react";
import {Button, Input, Table, Toggle} from "react-daisyui";
import {IoAddOutline} from "react-icons/io5";
import {TfiTrash} from "react-icons/tfi";
import {GetCurrentOpenModal, HandleOpenModal} from "./modal/ModalFactory";

export function Environments({data, onChange,onDelete}) {
    const containerRef = useRef(null);
    const [environments, setEnvironments] = useState(data?.filter(row => row?.inUse)?.length
        ? data
        : [{
            id: data?.length
                ? (Math.min(...data.map(row => row.id)) >= -1 ? -1 : Math.min(...data.map(row => row.id))) - 1
                : -1,
            name: "",
            description: "",
            enable: true,
            inUse: true,
        }]);

    useEffect(() => {
        // Scroll to the bottom when messages update
        containerRef.current.scrollIntoView({behavior: 'smooth'});
    }, [environments]);

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };

    return (
        <>
            <Table size={"xs mb-2"}>
                <Table.Head>
                    <span key={1}>Enable</span>
                    <span key={2}>Name</span>
                    <span key={3}>Description</span>
                    <span key={4}>Remove</span>
                </Table.Head>
                <Table.Body>
                    {environments
                        .filter(row => row.inUse)
                        .map(row =>
                            <Table.Row key={row.id} hover={true}>
                                <Toggle
                                    size={"xs"}
                                    checked={row.enable}
                                    color={"success"}
                                    onChange={event => {
                                        let param = environments.filter(param => param.id === row.id).at(0);
                                        param.enable = event.target.checked;
                                        setEnvironments([...environments]);
                                        onChangeFunction([...environments]);
                                    }}
                                />
                                <Input
                                    size={"xs"}
                                    className={"w-full"}
                                    placeholder={"Variable"}
                                    value={row.name}
                                    onChange={event => {
                                        let param = environments.filter(param => param.id === row.id).at(0);
                                        param.name = event.target.value;
                                        setEnvironments([...environments]);
                                        onChangeFunction([...environments]);
                                    }}
                                />
                                <Input
                                    size={"xs"}
                                    className={"w-full"}
                                    placeholder={"Value"}
                                    value={row.description}
                                    onChange={event => {
                                        let param = environments.filter(param => param.id === row.id).at(0);
                                        param.description = event.target.value;
                                        setEnvironments([...environments]);
                                        onChangeFunction([...environments]);
                                    }}/>
                                <Button size={"xs"} className={"bg-base-100 border-0"}
                                        onClick={() => {
                                            let modal = GetCurrentOpenModal();

                                            HandleOpenModal({
                                                title: 'Delete environment',
                                                type: 'confirmCancel',
                                                onConfirm: () => {
                                                    let param = environments.filter(param => param.id === row.id).at(0);
                                                    param.inUse = false;
                                                    setEnvironments([...environments]);
                                                    onChangeFunction([...environments]);
                                                    onDeleteFunction();
                                                },
                                                onClose: () => {
                                                    modal.content = [...environments];
                                                    HandleOpenModal(modal);
                                                },
                                                content: <>
                                                    <p className="pt-4">You are about to delete this variable.</p>
                                                    <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
                                                </>,
                                            })
                                        }}
                                >
                                    <TfiTrash/>
                                </Button>
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>
            <Button
                size={"sm"}
                className={"w-[70%] mx-[15%]"}
                variant={"outline"}
                onClick={event => {
                    event.preventDefault();
                    setEnvironments([...environments, {
                        id: Math.min(...environments.map(row => row.id)) > -1 ? -1
                            : Math.min(...environments.map(row => row.id)) - 1,
                        name: "",
                        description: "",
                        enable: true,
                        inUse: true,
                    }]);
                }}
            >
                <IoAddOutline/>
                Add Environment
            </Button>
            <div ref={containerRef}></div>
        </>

    )
}