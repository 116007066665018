import {Route, Routes} from "react-router-dom";
import * as React from "react";

export default function RouteBuilder({tabLink, addIndex}) {

    return (
        tabLink?.length ?
            <Routes>
                {getRoutes(tabLink, addIndex)}
            </Routes>
            : null);
}

function getRoutes(tabLink, addIndex) {
    let routes = [];
    if (!tabLink || tabLink.length === 0) {
        return routes;
    }

    let index = addIndex ? <Route key={-tabLink.at(0).id} index element={tabLink.at(0).element}/> : null;
    if (index) {
        routes = [...routes, index]
    }
    routes = [...routes,
        tabLink
            .map(link => <Route key={link.id} path={link.href} element={link.element}>
                {getRoutes(link.innerTabLinks, addIndex)}
            </Route>)];

    return routes;
}