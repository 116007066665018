import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const collectionUrl = "/Collection/v1/";
const newCollectionUrl = collectionUrl + "new";
const listCollectionUrl = collectionUrl + "list";

export function CollectionController(endpoint) {
    return {
        GetNewCollection: (setData) => GetNewCollection(endpoint, setData),
        GetCollection: (id, setData) => GetCollection(endpoint, id, setData),
        GetCollectionList: (setData) => GetCollectionList(endpoint, setData),
        PostCollection: (collection, setData) => PostCollection(endpoint, collection, setData),
        PutCollection: (collection, setData) => PutCollection(endpoint, collection, setData),
        DeleteCollection: (collection, setData) => DeleteCollection(endpoint, collection, setData),
    }
}

function GetNewCollection(endpoint, setData) {
    let rest = restGet(endpoint, newCollectionUrl);
    rest([], [], setData);
}

function GetCollection(endpoint, id, setData) {
    let rest = restGet(endpoint, collectionUrl);
    rest(new Map([["id", id]]), [], setData);
}

function GetCollectionList(endpoint, setData) {
    let rest = restGet(endpoint, listCollectionUrl);
    rest([], [], setData);
}

function PostCollection(endpoint, collection, setData) {
    let rest = restPost(endpoint, collectionUrl);
    rest(collection, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutCollection(endpoint, collection, setData) {
    let rest = restPut(endpoint, collectionUrl);
    rest(collection, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteCollection(endpoint, collection, setData) {
    let rest = restDelete(endpoint, collectionUrl);
    rest(collection, [], new Map([["Content-Type", "application/json"]]), setData);
}