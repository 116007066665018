import React from 'react';

const ChatBubble = ({message, isYou}) => {
    return (
        <div className={`chat-row`}>
            <div className={`chat-bubble ${!isYou ? 'theirChat' : 'empty'}`}>
                {!isYou ? message :""}
            </div>
            <div className={`chat-bubble ${isYou ? 'yourChat' : 'empty'}`}>
                {isYou ? message :""}
            </div>
        </div>
    );
};

export default ChatBubble;