import {restGet} from "../../hooks/rest";

const pageUrl = "/page/v1/";

export function PageController(endpoint) {
    return {
        GetPage: (id, setData) => GetPage(endpoint, id, setData),
    }
}

function GetPage(endpoint, id, setData) {
    let rest = restGet(endpoint, pageUrl + id.toString());
    rest([], [], setData);
}