import React, {useEffect, useState} from "react";
import {TfiTrash} from "react-icons/tfi";
import {Variables} from "../../../component/restful/Variables";
import {Loader} from "../../../component/Loader";
import {TitleBar} from "../../../component/restful/TitleBar";
import {HandleOpenModal} from "../../../component/modal/ModalFactory";
import {useRestfulApi} from "../../../hooks/useRestfulApi";

export function CollectionPage({collectionId, onChange, onSave, onDelete}) {
    let [collection, setCollection] = useState();
    let [environments, setEnvironments] = useState();
    let [collectionName, setCollectionName] = useState();
    let [save, setSave] = useState(true);
    let restfulApi = useRestfulApi();

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onSaveFunction = onSave ? onSave : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };

    useEffect(() => {
        if (!collection) {
            restfulApi?.collection?.GetCollection(collectionId, (data => {
                setCollection(data);
                setCollectionName(data.name);
                setSave(false);
            }));

            restfulApi?.environment.GetEnvironment((data) => {
                setEnvironments(data.map((environment) => {
                    return {
                        key: environment.id,
                        value: environment.name,
                    }
                }));
            });
        }

        return () => collection;
    }, [collection,collectionId,restfulApi?.collection,restfulApi?.environment]);

    return (
        <div className={"w-full"}>
            <Loader
                loading={save}
            >
                <TitleBar
                    title={collectionName}
                    environments={environments}
                    onSave={() => {
                        setSave(true);
                        restfulApi?.collection?.PutCollection(collection, (data) => {
                            onSaveFunction(data);
                            setCollection(data)
                            setSave(false);
                        })
                    }}
                    onChange={(title) => {
                        setCollectionName(title);
                        collection.name = title;
                        setCollection(collection);
                        onChangeFunction(collection);
                    }}
                    dropDownItems={[
                        {
                            onClick: () => HandleOpenModal({
                                title: 'Delete request',
                                type: 'confirmCancel',
                                onConfirm: () => {
                                    restfulApi?.collection?.DeleteCollection(collection);
                                    onDeleteFunction(collection);
                                },
                                content: <>
                                    <p className="pt-4">You are about to delete this request.</p>
                                    <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
                                </>,
                            }),
                            content: <><TfiTrash/> Delete</>,
                        }
                    ]}
                />

                <div className={"p-4"}>
                    <h1 className={"pl-4 text-3xl"}>Variables</h1>
                    <Variables
                        environments={(environments ? environments : [])}
                        onChange={(variable) => {
                            if (collection) {
                                collection.variables = variable;
                            }
                            setCollection(collection);
                        }}
                        data={collection ? collection.variables : []}/>
                </div>
            </Loader>
        </div>
    );
}