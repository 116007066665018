import Editor from '@monaco-editor/react';
import {Select} from "react-daisyui";
import React, {useState} from "react";
import {Loader} from "./Loader";

export default function CodeEditor({children, data, onChange, language}) {
    let [codeLanguage, setCodeLanguage] = useState(language.toLowerCase());
    let [refresh, setRefresh] = useState(false);
    const handleEditorChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };
    let content = data;

    if (typeof data === 'object') {
        content = JSON.stringify(data);
    }

    let items = [
        {key: 1, value: "abap"},
        {key: 2, value: "apex"},
        {key: 3, value: "azcli"},
        {key: 4, value: "bat"},
        {key: 5, value: "bicep"},
        {key: 6, value: "cameligo"},
        {key: 7, value: "clojure"},
        {key: 8, value: "coffee"},
        {key: 9, value: "cpp"},
        {key: 10, value: "csharp"},
        {key: 11, value: "csp"},
        {key: 12, value: "css"},
        {key: 13, value: "cypher"},
        {key: 14, value: "dart"},
        {key: 15, value: "dockerfile"},
        {key: 16, value: "ecl"},
        {key: 17, value: "elixir"},
        {key: 18, value: "flow9"},
        {key: 19, value: "freemarker2"},
        {key: 20, value: "fsharp"},
        {key: 21, value: "go"},
        {key: 22, value: "graphql"},
        {key: 23, value: "handlebars"},
        {key: 24, value: "hcl"},
        {key: 25, value: "html"},
        {key: 26, value: "ini"},
        {key: 27, value: "java"},
        {key: 28, value: "javascript"},
        {key: 29, value: "julia"},
        {key: 84, value: "json"},
        {key: 30, value: "kotlin"},
        {key: 31, value: "less"},
        {key: 32, value: "lexon"},
        {key: 33, value: "liquid"},
        {key: 34, value: "lua"},
        {key: 35, value: "m3"},
        {key: 36, value: "markdown"},
        {key: 37, value: "mdx"},
        {key: 38, value: "mips"},
        {key: 39, value: "msdax"},
        {key: 40, value: "mysql"},
        {key: 41, value: "objective-c"},
        {key: 42, value: "pascal"},
        {key: 43, value: "pascaligo"},
        {key: 44, value: "perl"},
        {key: 45, value: "pgsql"},
        {key: 46, value: "php"},
        {key: 47, value: "pla"},
        {key: 48, value: "postiats"},
        {key: 49, value: "powerquery"},
        {key: 50, value: "powershell"},
        {key: 51, value: "protobuf"},
        {key: 52, value: "pug"},
        {key: 53, value: "python"},
        {key: 54, value: "qsharp"},
        {key: 55, value: "r"},
        {key: 56, value: "razor"},
        {key: 57, value: "redis"},
        {key: 58, value: "redshift"},
        {key: 59, value: "restructuredtext"},
        {key: 60, value: "ruby"},
        {key: 61, value: "rust"},
        {key: 62, value: "sb"},
        {key: 63, value: "scala"},
        {key: 64, value: "scheme"},
        {key: 65, value: "scss"},
        {key: 66, value: "shell"},
        {key: 67, value: "solidity"},
        {key: 68, value: "sophia"},
        {key: 69, value: "sparql"},
        {key: 70, value: "sql"},
        {key: 71, value: "st"},
        {key: 72, value: "swift"},
        {key: 73, value: "systemverilog"},
        {key: 74, value: "tcl"},
        {key: 75, value: "test"},
        {key: 76, value: "text"},
        {key: 77, value: "twig"},
        {key: 78, value: "typescript"},
        {key: 79, value: "typespec"},
        {key: 80, value: "vb"},
        {key: 81, value: "wgsl"},
        {key: 82, value: "xml"},
        {key: 83, value: "yaml"},
    ];

    return (
        <div className={"h-full"}>
            <div className={"flex justify-between mb-1"}>
                <span>
                    {children}
                </span>
                <Select value={codeLanguage}
                        onChange={event => {
                            setCodeLanguage(event.target.value);
                            setRefresh(!refresh);
                        }}
                        size={"xs"}
                >
                    <Select.Option value={'default'} disabled>
                        Language
                    </Select.Option>
                    {items.map(option => <Select.Option key={option.key}>{option.value}</Select.Option>)}
                </Select>
            </div>
            <div className={"h-full"}>
                <Loader
                    loading={refresh}
                    timout={0}
                    onTimeout={() => setRefresh(false)}
                >
                    <Editor
                        className={"rounded"}
                        defaultLanguage={codeLanguage}
                        value={content}
                        theme={"vs-dark"}
                        onChange={handleEditorChange}
                        height={"100%"}
                        width={"100%"}
                    />
                </Loader>
            </div>
        </div>
    );
}