import {useState, useEffect} from "react";

function getStorageValue(key, defaultValue) {
    // getting stored value
    const saved = localStorage.getItem(key);
    try {
        return saved ? JSON.parse(saved) : defaultValue;
    } catch (e) {
        console.log(key);
        console.log(e);
        localStorage.removeItem(key);
    }
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value,setValue]);

    return [value, (value) => {
        setValue(value);
        localStorage.setItem(key, JSON.stringify(value));
    }];
};