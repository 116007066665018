import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeStringify from "rehype-stringify";
import rehypeSanitize from "rehype-sanitize";
import {TfiLink} from "react-icons/tfi";
import React, {useState} from "react";
import Highlight from "react-highlight";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {GoCheck, GoCopy} from "react-icons/go";

export function StyledMarkdown({content}) {
    return (<div className={"p-2"}>
        <Markdown
        remarkPlugins={[
            [remarkGfm, {singleTilde: false}],
            [remarkMath]]}
        rehypePlugins={[
            [rehypeStringify],
            [rehypeSanitize],
        ]}
        components={{
            a(props) {
                const {children, href} = props
                return <a href={href}
                          className={"text-blue-400 font-bold hover:underline hover:cursor-pointer"}>{children}</a>
            },
            h1(props) {
                const {children} = props
                return <div className={"flex relative text-6xl border-b-base-300 border-b-2 mt-6 mb-4 pb-3"}>
                    <h1 tabIndex={"-1"} dir={"auto"}>{children}</h1>
                    <a href={"#" + children} className={"text-4xl text-base-100 hover:text-base-content p-2"}>
                        <TfiLink/>
                    </a>
                </div>
            },
            h2(props) {
                const {children} = props
                return <div className={"flex relative text-4xl border-b-base-300 border-b-2 mt-6 mb-4 pb-1.5"}>
                    <h2 tabIndex={"-1"} dir={"auto"}>{children}</h2>
                    <a href={"#" + children} className={"text-xl text-base-100 hover:text-base-content p-2"}>
                        <TfiLink/>
                    </a>
                </div>
            },
            h3(props) {
                const {children} = props
                return <div className={"flex relative text-xl mt-5 mb-3 pb-1.5"}>
                    <h3 tabIndex={"-1"} dir={"auto"}>{children}</h3>
                    <a href={"#" + children} className={"text-lg text-base-100 hover:text-base-content p-2"}>
                        <TfiLink/>
                    </a>
                </div>
            },
            h4(props) {
                const {children} = props
                return <div className={"flex relative font-bold text-lg mt-5 mb-3 pb-1.5"}>
                    <h4 tabIndex={"-1"} dir={"auto"}>{children}</h4>
                    <a href={"#" + children} className={"text-md text-base-100 hover:text-base-content p-2"}>
                        <TfiLink/>
                    </a>
                </div>
            },
            p(props) {
                const {children} = props
                return <p className={"mb-4"}>{children}</p>
            },
            ul(props) {
                const {children} = props
                return <ul className={"pl-5 list-disc list-outside ..."}>{children}</ul>
            },
            ol(props) {
                const {children} = props
                return <ol className={"pl-5 list-decimal list-outside ..."}>{children}</ol>
            },
            img(props) {
                const {src, alt, title} = props
                return <img src={src} alt={alt} title={title} className={""}/>
            },

            code(props) {
                const {children, className, node, ...rest} = props
                return <Code
                    className={className}
                    node={node}
                    rest={rest}
                >
                    {children}
                </Code>
            }
        }}
    >
        {content ? content : null}
    </Markdown>
    </div>)
}

function Code({children, className, node, ...rest}) {
    const [copied, setCopied] = useState(false);
    const match = /language-(\w+)/.exec(className || '')

    return match ? (
        <div className={" flex justify-between bg-gray-800 text-lg" +
            " sm:text-xl pl-4 pt-1 pb-1 flex overflow-auto" +
            " rounded "}>
            <Highlight {...rest} className={className + ""}>
                {children}
            </Highlight>
            <CopyToClipboard text={children}
                             onCopy={() => copy(setCopied)}>
                <div className={"btn btn-ghost btn-sm text-gray-400 hover:bg-gray-600"}>
                                        <span>
                                            <GoCheck className={!copied ? "hidden" : "show"}/>
                                            <GoCopy className={copied ? "hidden" : "show"}/>
                                        </span>
                </div>
            </CopyToClipboard>
        </div>
    ) : (
        <code {...rest} className={className + " bg-base-300 pl-1 pr-1 rounded font-bold"}>
            {children}
        </code>
    );
}


const delay = ms => new Promise(res => setTimeout(res, ms));

function copy(setCopied) {
    setCopied(true);

    delay(1000)
        .then(() => setCopied(false));

}
