import React, {useEffect} from "react";
import {StyledMarkdown} from "../../component/markdown/StyledMarkdown";
import CodeEditor from "../../component/CodeEditor";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {Tabs} from "react-daisyui";


export default function MarkdownEditor() {
    let [doc, setDoc] = useLocalStorage("MarkdownEditor")
    let [showInput, setShoInput] = useLocalStorage('showInputMarkdownEditor', false);

    useEffect(() => {
        if (!doc) {
            setDoc("#New Markdown")
        }

        return () => doc;
    }, [doc, setDoc])

    return (
        <div className="max-h-[90vh] w-full">
            <Tabs variant={"bordered"} className={"md:hidden"}>
                <Tabs.Tab active={showInput}
                          onClick={() => setShoInput(true)}>
                    Code Editor
                </Tabs.Tab>
                <Tabs.Tab active={!showInput}
                          onClick={() => setShoInput(false)}>
                    Markdown preview
                </Tabs.Tab>
            </Tabs>
            <div className="flex p-2">
                <div
                    className={"h-[90] max-h-[89vh] w-full md:max-w-[50vw] min-w-[25vw] overflow-hidden hover:resize-x md:block "
                        + (showInput ? "block" : "hidden")}>
                    <CodeEditor
                        language={"markdown"}
                        data={doc}
                        onChange={(doc) => setDoc(doc)}>
                        <></>
                    </CodeEditor>
                </div>
                <div className={"max-h-[89vh] h-[89vh]  w-full md:max-w-[50vw]  m-auto overflow-auto  md:block "
                    + (!showInput ? "block" : "hidden")}>
                    <StyledMarkdown content={doc}/>
                </div>
            </div>
        </div>
    );
}