import {TfiFolder, TfiPencilAlt, TfiTrash} from "react-icons/tfi";
import React from "react";
import {RestRequestListItem} from "./RestRequestListItem";
import {RiMailAddLine} from "react-icons/ri";
import {FiFolderPlus} from "react-icons/fi";
import {HandleOnContext} from "../ImportContextMenu";
import {HandleOpenModal} from "../modal/ModalFactory";
import {IoAddOutline} from "react-icons/io5";
import {useRestfulApi} from "../../hooks/useRestfulApi";

export function FolderListItem({
                                   collections,
                                   collectionId,
                                   folders,
                                   filter,
                                   onSelectRequest,
                                   onUpdate,
                                   onPutRequest,
                                   onGetRequest,

                                   onNewRequest,
                                   onNewFolder,

                                   onDuplicateRequest,

                                   onUpdateNameFolder,
                                   onUpdateNameRequest,

                                   onDeleteFolder,
                                   onDeleteRequest,
                               }) {


    return (
        <>
            {(folders ? folders : [])
                .filter(row => row && row.name && row.name.toLowerCase()
                    .includes(filter))
                .map(folder =>
                    <li key={folder.id}>
                        <details>
                            <summary className={"flex   overflow-clip md:max-w-[10rem]"}
                                     onContextMenu={event => HandleOnContext(event, folder.id,
                                         GetContextButtons(onNewRequest, onNewFolder, collectionId, onUpdate,
                                             () => {
                                                 HandleOpenModal({
                                                     title: 'Delete Folder',
                                                     type: 'confirmCancel',
                                                     onConfirm: () => onDeleteFolder(folder.id, () => {
                                                         onUpdate(folder);
                                                     }),
                                                     content: <>
                                                         <p className="pt-4">You are about to delete this
                                                             folder.</p>
                                                         <p className="pt-1 pb-4">Are you sure you want to delete
                                                             it?</p>
                                                     </>,
                                                 })
                                             },
                                             () => HandleOpenModal({
                                                 title: 'Rename Folder',
                                                 type: 'Input',
                                                 onConfirm: (value) => onUpdateNameFolder(folder.id, value, onUpdate),
                                                 content: folder.name,
                                             })
                                         ))}>
                                <TfiFolder/>
                                <a className={"truncate ... text-m font-medium md:max-w-[10rem]"}>
                                    {folder.name}
                                </a>
                            </summary>
                            <ul className={"menu-xs md:max-w-[10rem]"}>
                                <FolderListItem
                                    collections={collections}
                                    collectionId={collectionId}
                                    onSelectRequest={onSelectRequest}
                                    filter={filter}
                                    folders={folder.folders ? folder.folders : []}
                                    onUpdate={onUpdate}
                                    onPutRequest={onPutRequest}
                                    onGetRequest={onGetRequest}

                                    onNewRequest={onNewRequest}
                                    onNewFolder={onNewFolder}

                                    onUpdateNameFolder={onUpdateNameFolder}
                                    onUpdateNameRequest={onUpdateNameRequest}
                                    onDuplicateRequest={onDuplicateRequest}
                                    onDeleteRequest={onDeleteRequest}
                                    onDeleteFolder={onDeleteFolder}
                                />
                                <RestRequestListItem
                                    collections={collections}
                                    filter={filter}
                                    restfulDetails={folder.restfulDetails ? folder.restfulDetails : []}
                                    onSelectRequest={onSelectRequest}
                                    collectionId={collectionId}
                                    onUpdate={onUpdate}
                                    onPutRequest={onPutRequest}

                                    onGetRequest={onGetRequest}
                                    onDuplicateRequest={onDuplicateRequest}
                                    onUpdateNameRequest={onUpdateNameRequest}

                                    onDeleteRequest={onDeleteRequest}
                                />
                            </ul>
                        </details>
                    </li>
                )}
        </>
    );
}

function GetContextButtons(onNewRequest, onNewFolder, collectionId, onUpdate, onDelete, onRename) {
    let onUpdateFunction = onUpdate ? onUpdate : () => {
    };

    let onRenameFunction = onRename ? onRename : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };


    return [
        {
            content: <><IoAddOutline/> New</>,
            isGroup: true,
            isOpen: true,
            buttons: [
                {
                    content: <><RiMailAddLine/> Add new request</>,
                    onClick: (folderId) => onNewRequest(collectionId, folderId, () => onUpdateFunction ? onUpdateFunction(collectionId) : () => {
                    }),
                },
                {
                    content: <><FiFolderPlus/>Add new Folder</>,
                    onClick: (folderId) => onNewFolder(collectionId, folderId, () => onUpdateFunction ? onUpdateFunction(collectionId) : () => {
                    }),
                },
            ]
        },
        {
            content: <><TfiPencilAlt/> Maintain</>,
            isGroup: true,
            buttons: [
                {
                    content: <><TfiPencilAlt/> rename</>,
                    onClick: () => onRenameFunction(),
                },
                {
                    content: <><TfiTrash/> Delete</>,
                    onClick: () => onDeleteFunction(),
                }
            ]
        },
    ];
}
