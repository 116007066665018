import React, {useEffect, useRef, useState} from "react";
import {Input} from "react-daisyui";
import {ConfirmCancelModal} from "./ConfirmCancelModal";

export function InputModal({visible, placeholder, title, value, onChange, onClose, onCancel, onConfirm}) {
    const modalRef = useRef(null);

    let [inputValue,setInputValue] = useState(value ? value : "");

    let onChangeFunction = onChange ? onChange : () => {
    };
    let onConfirmFunction = onConfirm ? onConfirm: () => {
        };
    useEffect(() => {
        if (!modalRef.current) {
            return;
        }
        visible ? modalRef.current.showModal() : modalRef.current.close();
    }, [visible]);

    return (<ConfirmCancelModal visible={visible}
                                onClose={onClose}
                                onCancel={onCancel}
                                onConfirm={() => {
                                    onConfirmFunction(inputValue);
                                }}>
            {title ? <h3 className="font-bold text-lg"> {title}</h3> : null}
            <Input
                className={"w-full"}
                placeholder={placeholder}
                value={inputValue}
                onChange={(event) =>{
                    setInputValue(event.target.value);
                    onChangeFunction(event.target.value);
                }}
            />
        </ConfirmCancelModal>
    );
}