import {restPost} from "../../hooks/rest";

const messagingUrl = "/messaging/api/v1/";
const messageMessagingUrl = messagingUrl + "Message/";

export function MessagingController(endpoint) {
    return {
        PostMessage: (message, setData) => PostRestfulDetail(endpoint, message, setData),
    }
}

function PostRestfulDetail(endpoint, message, setData) {
    let rest = restPost(endpoint, messageMessagingUrl);
    rest(message, [], new Map([["Content-Type", "application/json"]]), setData);
}
