import {useFakerApi} from "../../../hooks/useRestfulApi";
import React, {useEffect, useState} from "react";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {Tabs} from "react-daisyui";
import {TabPage} from "../../../component/TabPage";
import {CollectionList} from "../../../component/restful/CollectionList";
import {IoGitBranchSharp} from "react-icons/io5";

export function Faker() {
    let [apiList,setApiList] = useState();
    let [restfulTabs, setFakerTabs] = useLocalStorage('fakerTabs', []);
    let fakerApi = useFakerApi();

    let [showApis, setShowApis] = useLocalStorage('showApis', false);

    useEffect(() => {
        if(!apiList){
            fakerApi.api.GetAPIList(data => setApiList(data))
        }

        return () => apiList;
    }, [apiList]);

    return (
        <div className={"w-full min-h-[90vh] block md:flex"}>
            <Tabs variant={"bordered"} className={"md:hidden"}>
                <Tabs.Tab active={showApis}
                          onClick={() => setShowApis(true)}>
                    Mock Api's
                </Tabs.Tab>
                <Tabs.Tab active={!showApis}
                          onClick={() => setShowApis(false)}>
                    Response
                </Tabs.Tab>
            </Tabs>
            <span className={"md:block" + (showApis ? " block" : " hidden")}>
            <CollectionList
                collections={apiList}

                onNewCollection={(apis,onUpdated) =>fakerApi.api.GetNewAPI((data) => onUpdated([...apis, data]))}
                onNewRequest={(apiId, folderID, onUpdated) => fakerApi.mock.GetNewMock(apiId,  () => onUpdated())}
                onNewFolder={(collectionId, folderID, onUpdated) => {}}

                onUpdateNameFolder={(folderId, value, onUpdate) => {}}
                onUpdateNameRequest={(id, value, onUpdate) => {
                    fakerApi.mock.GetMock(id, (mock) => {

                        if (value) {
                            mock.name = value;
                        }

                        fakerApi.mock.PutMock(mock, (data) => onUpdate(data));
                    })
                }}
                onGetRequest={(id, setData) => fakerApi.mock.GetMock(id, setData)}
                onDuplicateRequest={(mock, setData) => fakerApi.mock.PostMock(mock, (dupData) => {
                        dupData.request.variables = mock.variables;
                        setData(dupData);
                    }
                )}
                onPutRequest={(mock, setData) => fakerApi.mock.PutMock(mock, setData)}



                onCollectionsUpdated={() => {
                    fakerApi.api.GetAPIList(data => setApiList([...data]))
                }}

                onOpenCollection={(api) => {
                    if (restfulTabs?.filter(tab => tab.key === "_" + api.id).length) {
                        let tab = restfulTabs?.filter(tab => tab.key === "_" + api.id);
                        tab.focus = true;
                        setFakerTabs([...restfulTabs]);
                        return;
                    }

                    setFakerTabs([...restfulTabs, {
                        key: "_" + api.id,
                        id: api.id,
                        type: "ApiPage",
                        label: api.name,
                        focus: true,
                        onSave: () => {
                            fakerApi.api.GetAPIList(data => setApiList([...data]));
                        },
                        onDelete: () => {
                            fakerApi.api.GetAPIList(data =>{
                                setFakerTabs(...restfulTabs?.filter(tab => tab.key !== "_" + api.id))
                                setApiList([...data]);
                            });
                        },
                        onChange: () => {
                        }
                    }]);
                }}
                onSelectRequest={(collectionId, restDetail) => {
                    if (restfulTabs?.filter(tab => tab.key === restDetail.id + "_" + restDetail.collectionId).length) {
                        let tab = restfulTabs?.filter(tab => tab.key === restDetail.id + "_" + restDetail.collectionId);
                        tab.focus = true;
                        setFakerTabs([...restfulTabs]);
                        return;
                    }
                    if (!restfulTabs) {
                        restfulTabs = []
                    }

                    setFakerTabs([...restfulTabs, {
                        key: restDetail.id + "_" + restDetail.collectionId,
                        id: restDetail.id,
                        type: "FakerConfig",
                        focus: !restfulTabs || restfulTabs.length <= 0,
                        label: restDetail.httpmethod + ": " + restDetail.label,
                        onSave: () => {
                            fakerApi.api.GetAPIList(data => setApiList([...data]));
                        },
                        onDelete: () => {
                            fakerApi.api.GetAPIList(data => {
                                setFakerTabs(...restfulTabs?.filter(tab => tab.key !== restDetail.id + "_" + restDetail.collectionId))
                                setApiList([...data]);
                            });
                        },
                        onChange: () => {
                        }
                    }]);
                }}
                onCollectionDelete={() => {
                    fakerApi.api.GetAPIList(data => setApiList([...data]));
                }}
            />
        </span>

            <span className={"w-full md:max-w-full md:block" + (showApis ? " hidden" : " block")}>
            {restfulTabs?.length
                ? <TabPage
                    localStorageKey={'restful'}
                    tabs={restfulTabs}
                    onOpen={(tab) => {
                        let tabToUpdate = restfulTabs?.filter(tabToUpdate => tabToUpdate.key === tab.key);
                        tabToUpdate.focus = false;
                        setFakerTabs([...restfulTabs]);
                    }}
                    onchange={(tab) => {
                        let update = restfulTabs?.filter(row => row.key === tab.key).at(0);
                        update.changed = true;
                        setFakerTabs([...restfulTabs]);
                    }}
                    onSave={(tab) => {
                        let update = restfulTabs?.filter(row => row.key === tab.key).at(0);
                        update.changed = false;
                        update.label = tab.label;
                        setFakerTabs([...restfulTabs]);

                        fakerApi.api.GetAPIList(data => setApiList([...data]));
                    }}
                    onClose={(tab) => {
                        let update = restfulTabs?.filter(row => row.key !== tab.key);
                        setFakerTabs([...update]);
                    }}
                />
                : <div className="grid max-w-full w-full max-h-full h-full place-items-center">
                    <div className={"text-[10rem] md:text-[30rem] text-base-content"}>
                        <IoGitBranchSharp />
                    </div>
                </div>}
        </span>
        </div>
    )
}