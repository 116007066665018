import {CollectionController} from "../api/restful/CollectionController";
import {RequestController} from "../api/restful/RequestRestController";
import {RestfulDetailController} from "../api/restful/RestfulDetailController";
import {LinkController} from "../api/restful/LinkController";
import {MessagingController} from "../api/restful/MessageController";
import {PageController} from "../api/restful/PageController";
import {ContentApi, MessagingApi, RestfulApi} from "../api/restful/RestfulApi";
import {FolderController} from "../api/restful/FolderRestController";
import {EnvironmentController} from "../api/restful/EnvironmentController";
import {VariableController} from "../api/restful/VariableController";
import {APIController} from "../api/restful/FakerAPIController";
import {MockController} from "../api/restful/FakerMockController";

export function useRestfulApi() {
    let endpoint = RestfulApi();

    return {
        collection: CollectionController(endpoint),
        folder: FolderController(endpoint),
        request: RequestController(endpoint),
        restfulDetail: RestfulDetailController(endpoint),
        environment: EnvironmentController(endpoint),
        variable: VariableController(endpoint),
    }
}

export function useMessagingApi() {
    let endpoint = MessagingApi();

    return {
        messaging: MessagingController(endpoint),
    }
}

export function useContentApi() {
    let endpoint = ContentApi();

    return {
        link: LinkController(endpoint),
        page: PageController(endpoint),
    }
}

export function useFakerApi() {
    let endpoint = RestfulApi();

    return {
        api: APIController(endpoint),
        mock: MockController(endpoint),
    }
}