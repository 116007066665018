import {Link, useMatch, useResolvedPath} from "react-router-dom";

function LinkItem({href, listItemClass, linkItemClass, children, onClick, ...props}) {
    const resolvedPath = useResolvedPath(href)
    if(useMatch({path: resolvedPath.pathname,end: true})){
        listItemClass = listItemClass + " active";
        listItemClass = listItemClass.trim();

        linkItemClass = linkItemClass + " active";
        linkItemClass = linkItemClass.trim();
    }

    return (
        <li className={listItemClass}>
            <Link className={linkItemClass} to={href} onClick={onClick}>{children}</Link>
        </li>
    );
}

export default LinkItem;
