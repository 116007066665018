import {Tabs} from "react-daisyui";

export default function OptionSection({
                                          data,
                                          responseOptions,
                                          className,
                                          page,
                                          onChange
                                      }) {
    let onchangeUpdated = onChange
        ? onChange
        : () => {
        };

    return (
        <div>
            {data ?
                <Tabs variant={"bordered"}>
                    {responseOptions.length ? responseOptions.map(option =>
                        <Tabs.Tab key={option.id} active={option.label === page}
                                  onClick={() => onchangeUpdated(option.label)}>
                            {option.label}
                        </Tabs.Tab>) : null}
                </Tabs> : null}
            <div className={className}>
                {page && responseOptions.filter(option => option.label === page).at(0)
                    ? responseOptions.filter(option => option.label === page).at(0).element
                    : responseOptions.at(0).element}
            </div>
        </div>);
}
