import {Modal} from "./Modal";

export function ConfirmCancelModal({children, visible, onClose, onCancel, onConfirm}) {

    let onCloseFunction = onClose ? onClose : () => {
    };

    let onCancelFunction = onCancel ? onCancel : () => {
    };

    let onContinueFunction = onConfirm ? onConfirm : () => {
    };

    return (
        <Modal
            onClose={onClose}
            visible={visible}
        >
            {children}
            <div className="modal-action">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-success" onClick={() => {
                    onContinueFunction();
                    onCloseFunction();
                }}>
                    Confirm
                </button>
                <button className="btn btn-error" onClick={() => {
                    onCancelFunction();
                    onCloseFunction();
                }}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
}