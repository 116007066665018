import * as React from 'react'
import {useEffect, useRef, useState} from "react";
import {Button, Input, Table, Toggle} from "react-daisyui";
import {IoAddOutline} from "react-icons/io5";
import {TfiTrash} from "react-icons/tfi";
import {ConfirmCancelModal} from "../modal/ConfirmCancelModal";

export function Params(restParamData) {
    const chatContainerRef = useRef(null);
    const [deleteParameter, setDeleteParameter] = useState(null);
    const [params, setParams] = useState(restParamData.data && restParamData.data.length > -1
        ? restParamData.data
        : [{
            id: restParamData.data && restParamData.data.length > -1
                ? (Math.min(...restParamData.data.map(row => row.id)) >= -1 ? -1 : Math.min(...restParamData.data.map(row => row.id))) - 1
                : -1,
            add: true,
            inUse: true,
            key: "",
            value: "",
            description: "",
        }]);

    useEffect(() => {
        // Scroll to the bottom when messages update
        chatContainerRef.current.scrollIntoView({behavior: 'smooth'});
    }, [restParamData.data]);

    let onChange = restParamData.onChange
        ? restParamData.onChange
        : () => {
        };

    return (
        <>
            <Table size={"xs mb-2"}>
                <Table.Head>
                    <span key={1}>Include</span>
                    <span key={2}>Parameter</span>
                    <span key={3}>Value</span>
                    <span key={4}>Description</span>
                    <span key={5}>Remove</span>
                </Table.Head>
                <Table.Body>
                    {params
                        .filter(row => row.inUse)
                        .map(row =>
                        <Table.Row key={row.id} hover={true}>
                            <Toggle
                                size={"xs"}
                                checked={row.add}
                                color={"success"}
                                onChange={event => {
                                    let param = params.filter(param => param.id === row.id).at(0);
                                    param.add = event.target.checked;
                                    setParams([...params]);
                                    onChange([...params]);
                                }}
                            />
                            <Input
                                size={"xs"}
                                className={"w-full"}
                                placeholder={"Parameter"}
                                value={row.key}
                                onChange={event => {
                                    let param = params.filter(param => param.id === row.id).at(0);
                                    param.key = event.target.value;
                                    setParams([...params]);
                                    onChange([...params]);
                                }}
                            />
                            <Input
                                size={"xs"}
                                className={"w-full"}
                                placeholder={"Value"}
                                value={row.value}
                                onChange={event => {
                                    let param = params.filter(param => param.id === row.id).at(0);
                                    param.value = event.target.value;
                                    setParams([...params]);
                                    onChange([...params]);
                                }}/>
                            <Input
                                size={"xs"}
                                className={"w-full"}
                                placeholder={"Description"}
                                value={row.description}
                                onChange={event => {
                                    let param = params.filter(param => param.id === row.id).at(0);
                                    param.description = event.target.value;
                                    setParams([...params]);
                                    onChange([...params]);
                                }}/>
                            <Button size={"xs"} className={"bg-base-100 border-0"}
                                    onClick={() => setDeleteParameter(row.id)}>
                            <TfiTrash/>
                            </Button>
                        </Table.Row>)}
                </Table.Body>
            </Table>
            <Button
                size={"sm"}
                className={"w-[70%] mx-[15%]"}
                variant={"outline"}
                onClick={event => {
                    event.preventDefault();
                    setParams([...params, {
                        id: Math.min(...params.map(row => row.id)) > -1 ? -1
                            : Math.min(...params.map(row => row.id)) - 1,
                        add: true,
                        inUse: true,
                        key: "",
                        value: "",
                        description: "",
                    }]);
                }}
            >
                <IoAddOutline/>
                Add Parameters
            </Button>
            <div ref={chatContainerRef}></div>
            <ConfirmCancelModal
                visible={deleteParameter !== null}
                onClose={() => setDeleteParameter(null)}
                onConfirm={() => {
                    let param = params.filter(param => param.id === deleteParameter).at(0);
                    param.inUse = false;
                    setParams([...params]);
                    onChange([...params]);
                    if(params
                        .filter(row => row.inUse)
                        .filter(param => param.id !== deleteParameter).length <=0 ){
                        setParams([...params, {
                            id: Math.min(...params.map(row => row.id)) > -1 ? -1
                                : Math.min(...params.map(row => row.id)) - 1,
                            add: true,
                            inUse: true,
                            key: "",
                            value: "",
                            description: "",
                        }]);
                    }
                }}
            >
                <h3 className="font-bold text-lg">Delete parameter</h3>
                <p className="pt-4">You are about to delete this parameter.</p>
                <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
            </ConfirmCancelModal>
        </>

    )
}