import {restDelete, restGet, restPost, restPut} from "../../hooks/rest";

const folderUrl = "/Folder/v1/";
const newFolderUrl = folderUrl + "new";

export function FolderController(endpoint) {
    return {
        GetNewFolder: (collectionId, folderId, setData) => GetNewFolder(endpoint, collectionId, folderId, setData),
        GetFolder: (folderId, setData) => GetFolder(endpoint,folderId, setData),
        PostFolder: (folder, setData) => PostFolder(endpoint, folder, setData),
        PutFolder: (folder, setData) => PutFolder(endpoint, folder, setData),
        DeleteFolder: (folder, setData) => DeleteFolder(endpoint, folder, setData),
    }
}

function GetNewFolder(endpoint, collectionId, folderId, setData) {
    let rest = restGet(endpoint, newFolderUrl);
    rest(new Map([["collectionId", collectionId], ["folderId", folderId]]), [], setData);
}

function GetFolder(endpoint,folderId, setData) {
    let rest = restGet(endpoint, folderUrl);
    rest(new Map([["id", folderId]]), [], setData);
}

function PostFolder(endpoint, folder, setData) {
    let rest = restPost(endpoint, folderUrl);
    rest(folder, [], new Map([["Content-Type", "application/json"]]), setData);
}

function PutFolder(endpoint, folder, setData) {
    let rest = restPut(endpoint, folderUrl);
    rest(folder, [], new Map([["Content-Type", "application/json"]]), setData);
}

function DeleteFolder(endpoint, folder, setData) {
    let rest = restDelete(endpoint, folderUrl);
    rest(folder, [], new Map([["Content-Type", "application/json"]]), setData);
}