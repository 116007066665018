import * as React from "react";
import {Outlet} from "react-router-dom";
import {GetSmallScreenLinks} from "../../component/tabBar/TabBar";
import {RxDoubleArrowLeft, RxDoubleArrowRight} from "react-icons/rx";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import LinkItem from "../../component/tabBar/linkItem";
import {Tooltip} from "react-daisyui";

export default function MultiPage({parentHref, navigationalLinks}) {
    let [isOpen, setIsOpen] = useLocalStorage(parentHref + "-isOpen", false);


    return (

        <div className="flex max-w-screen">
            <div className={"hidden lg:block  xl:block h-[83vh] " + (isOpen ? " w-72" : null)}>
                    {isOpen
                        ? <ul tabIndex="0" className="menu bg-base-200 text-base-content p-4 h-[85vh] max-h-[85vh]">
                            {GetSmallScreenLinks(navigationalLinks)}
                        </ul>
                        : <ul tabIndex="0" className="menu bg-base-200 text-base-content p-4 h-[85vh] max-h-[85vh]">
                            {getIconScreenLinks(navigationalLinks)}
                        </ul>}
                    {isOpen
                        ? <button className={"btn w-full mt-auto"} onClick={() => setIsOpen(!isOpen)}>
                            <RxDoubleArrowLeft/> Close menu</button>
                        : <button className={"btn w-full mt-auto"} onClick={() => setIsOpen(!isOpen)}>
                            <RxDoubleArrowRight/>
                        </button>}
                </div>
                <Outlet/>
            </div>
            );
            }

            export function getIconScreenLinks(tabLinks, baseTab) {
            let updatedBaseTab = baseTab && baseTab !== "" && !baseTab.endsWith('/')
        ? baseTab + '/' : baseTab;

    return tabLinks
        .filter(link => link.label !== null)
        .map(link => {
            return <Tooltip
                key={link.id}
                position={'right'}
                message={link.label}>
                <LinkItem key={link.id}
                          href={(updatedBaseTab ? updatedBaseTab : "") + link.href}>{link.label.at(0).toUpperCase()}
                </LinkItem>
            </Tooltip>
        });
}

