import React, {useEffect, useState} from "react";
import {useFakerApi} from "../../../hooks/useRestfulApi";
import {Loader} from "../../../component/Loader";
import {TitleBar} from "../../../component/restful/TitleBar";
import {HandleOpenModal} from "../../../component/modal/ModalFactory";
import {TbVariable} from "react-icons/tb";
import {RiJavascriptFill} from "react-icons/ri";
import {TfiTrash} from "react-icons/tfi";
import {Input, Select} from "react-daisyui";
import CodeEditor from "../../../component/CodeEditor";

export function FakerConfig({tabKey, mockId, data, onChange, onDelete, onSave}) {
    let fakerApi = useFakerApi();
    let [fakerConfig, setFakerConfig] = useState(data);

    let [title, setTitle] = useState(data?.name ? data.name : "");
    let [httpMethod, setHttpMethod] = useState(data?.httpMethod ? data.httpMethod : "GET");
    let [resource, setResource] = useState(data?.resource ? data.resource : "");
    let [response, setResponse] = useState(data?.responses?.length ? data.responses.at(0) : {});

    let [refreshPage, setRefreshPage] = useState(false);


    useEffect(() => {
        if (!fakerConfig) {
            fakerApi.mock.GetMock(mockId, data => {
                setTitle(data.name)
                setHttpMethod(data.httpMethod)
                setResource(data.resource)
                setResponse(data?.responses?.length ? data.responses.at(0) : {})

                setFakerConfig(data);
            });
        }

        return () => fakerConfig;
    }, [fakerConfig]);

    let onChangeFunction = onChange ? onChange : () => {
    };

    let onSaveFunction = onSave ? onSave : () => {
    };

    let onDeleteFunction = onDelete ? onDelete : () => {
    };

    let items = [
        {key: 1, displayValue: "GET", value: "GET"},
        {key: 2, displayValue: "POST", value: "POST"},
        {key: 3, displayValue: "PUT", value: "PUT"},
        {key: 4, displayValue: "PATCH", value: "PATCH"},
        {key: 5, displayValue: "DELETE", value: "DELETE"},
    ];

    return (
        <Loader loading={!fakerConfig || refreshPage}>
            <div className={"w-full md:max-w-[80vw] h-[80vh]"}>
                <TitleBar
                    title={title}
                    showEnvironmentSelect={false}
                    onSave={() => {
                        setRefreshPage(true);
                        fakerApi.mock.PutMock(fakerConfig, (data) => {

                            if (!data.id) {
                                setRefreshPage(false);
                                return;
                            }

                            setRefreshPage(false);
                            onSaveFunction(data);
                            setFakerConfig(data);
                        })
                    }}
                    onChange={(title) => {
                        fakerConfig.name = title;
                        setTitle(title);
                        setFakerConfig(fakerConfig);
                        onChangeFunction(fakerConfig);
                    }}
                    dropDownItems={getNamBarOptions(fakerApi, fakerConfig, onChangeFunction, onDeleteFunction, setRefreshPage)}
                />
                <div className={"w-full join pl-4 pr-4"}>
                    <Select
                        className={"w-full join-item hidden md:block md:max-w-32"}
                        value={httpMethod}
                        onChange={event => {
                            fakerConfig.httpMethod = event.target.value;
                            setHttpMethod(fakerConfig.httpMethod);
                            onChangeFunction(fakerConfig);
                        }}>
                        <Select.Option value={'default'} disabled>
                            Http Method
                        </Select.Option>
                        {items.map(option => <Select.Option key={option.key}>{option.displayValue}</Select.Option>)}
                    </Select>

                    <Input className={"join-item w-full"}
                           value={resource}
                           placeholder={"Mock resource"}
                           onChange={(event) => {
                               setResource(event.target.value);
                               fakerConfig.resource = event.target.value;
                               setFakerConfig(fakerConfig);
                           }}
                    />
                </div>
                <div className={"w-full pl-4 pr-4 h-full"}>
                    <CodeEditor
                        language={"JSON"}
                        data={response?.data ? response.data : {}}
                        onChange={value => {

                            response = response ? response : {};
                            response.data = value
                            response.default = true;
                            response.inUse = true;
                            response.name = "temp";
                            response.mockId = fakerConfig.id;

                            fakerConfig.responses = [response]

                            setResponse(response);
                            setFakerConfig(fakerConfig)
                        }}
                    >
                        Response
                    </CodeEditor>
                </div>
                </div>
        </Loader>
);
}

function getNamBarOptions(fakerApi, fakerConfig, onChange, onDelete, setRefreshPage) {
    return [
        {
            onClick: () => HandleOpenModal({
                type: 'variable',
                environments: ([]),
                onDelete: () => {
                    setRefreshPage(true);
                    new Promise(res => setTimeout(setRefreshPage(false), 1000));
                },
                onChange: (variables) => {
                    if (fakerConfig?.request?.variables) {
                        fakerConfig.request.variables = variables;
                    }
                    onChange(fakerConfig);
                },
                content: fakerConfig?.request?.variables ? fakerConfig.request.variables : null,
            }),
            content: <><TbVariable/> Variables</>,
        },
        {
            onClick: () => HandleOpenModal({
                type: 'script',
                environments: ([]),
                onDelete: () => {
                    setRefreshPage(true);
                    new Promise(res => setTimeout(setRefreshPage(false), 1000));
                },
                onChange: (preScripts) => {
                    if (fakerConfig?.request?.preScripts) {
                        fakerConfig.request.preScripts = preScripts;
                    }
                    onChange(fakerConfig);
                },
                content: fakerConfig?.request?.preScripts ? fakerConfig.request.preScripts : null,
            }),
            content: <><RiJavascriptFill/> Pre-request script</>,
        },
        {
            onClick: () => HandleOpenModal({
                type: 'script',
                environments: ([]),
                onDelete: () => {
                    setRefreshPage(true);
                    new Promise(res => setTimeout(setRefreshPage(false), 1000));
                },
                onChange: (postScripts) => {
                    if (fakerConfig?.request?.postScripts) {
                        fakerConfig.request.postScripts = postScripts;
                    }
                    onChange(fakerConfig);
                },
                content: fakerConfig?.request?.postScripts ? fakerConfig.request.postScripts : null,
            }),
            content: <><RiJavascriptFill/> Post-request script</>,
        },
        {
            onClick: () => HandleOpenModal({
                title: 'Delete request',
                type: 'confirmCancel',
                onConfirm: () => {
                    fakerApi.mock.DeleteMock(fakerConfig, (data) => onDelete(data));
                },
                content: <>
                    <p className="pt-4">You are about to delete this request.</p>
                    <p className="pt-1 pb-4">Are you sure you want to delete it?</p>
                </>,
            }),
            content: <><TfiTrash/> Delete</>,
        }
    ];
}