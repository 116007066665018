import './Login.css';
import React from "react";

function Login() {
    return (
        <div className="login">
            <br/>
            <br/>
        </div>
    );
}

export default Login;
