import {IoAddOutline} from "react-icons/io5";
import CollectionListItem from "./CollectionListItem";
import React, {useState} from "react";
import {Button, Tooltip, Input} from "react-daisyui";
import {MdSort} from "react-icons/md";

export function CollectionList({
                                   collections,
                                   onCollectionsUpdated,

                                   onGetRequest,
                                   onDuplicateRequest,
                                   onNewCollection,
                                   onNewRequest,
                                   onNewFolder,

                                   onUpdateNameFolder,
                                   onUpdateNameRequest,

                                   onDeleteCollection,
                                   onDeleteRequest,
                                   onDeleteFolder,

                                   onPutRequest,

                                   onOpenCollection,
                                   onSelectRequest,
                               }) {
    const [collectionFilter, setCollectionFilter] = useState("");

    let onCollectionsUpdatedFunction = onCollectionsUpdated ? onCollectionsUpdated : {};


    return (
        <div className="h-[83vh] max-h-[83vh]">
            <div className="w-full p-4 font-sans join">
                <Tooltip
                    message="Sort collection list" position={"right"}>
                    <Button
                        size={"sm"}
                        className={"join-item"}
                        variant={"outline"}

                    >
                        <MdSort/>
                    </Button>
                </Tooltip>
                <Input
                    size={"sm"}
                    className={"w-full join-item"}
                    onChange={event => setCollectionFilter(event.target.value ? event.target.value.toLowerCase() : "")}
                    placeholder={"Search"}
                />
                <Tooltip
                    message="Add New Collection" position={"left"}>
                    <Button
                        size={"sm"}
                        className={"hidden md:block md:join-item "}
                        onClick={() => onNewCollection(collections,onCollectionsUpdatedFunction)}
                        color={"secondary"}>
                        <IoAddOutline/>
                    </Button>
                </Tooltip>

            </div>
            <div className={"h-[81vh] max-h-[81vh] overflow-scroll overflow-x-auto overflow-y-auto"}>
                {collections && collections.length > 0 ?
                    <CollectionListItem
                        collections={collections}
                        filter={collectionFilter}
                        onCollectionUpdate={onCollectionsUpdated}
                        onSelectRequest={onSelectRequest}
                        onOpenCollection={onOpenCollection}
                        onPutRequest={onPutRequest}
                        onGetRequest={onGetRequest}
                        onDuplicateRequest={onDuplicateRequest}

                        onNewRequest={onNewRequest}
                        onNewFolder={onNewFolder}

                        onUpdateNameFolder={onUpdateNameFolder}
                        onUpdateNameRequest={onUpdateNameRequest}

                        onDeleteCollection={onDeleteCollection}
                        onDeleteFolder={onDeleteFolder}
                        onDeleteRequest={onDeleteRequest}
                    />
                    : <ul className={"menu"}>
                        <p className={"menu-title whitespace-break-spaces max-w-full md:max-w-xs"}>No collection
                            present.</p>
                        <p className={"whitespace-break-spaces max-w-full md:max-w-xs"}>It Looks like you don't
                            have any collections created.</p>
                        <li className={"mt-2"}>
                            <span
                                className={"bg-secondary text-secondary-content hidden md:block"}
                                onClick={() => onNewCollection(collections,onCollectionsUpdatedFunction)}
                            >
                                <IoAddOutline/> Add collection
                            </span>
                        </li>
                        <p className={"whitespace-break-spaces max-w-full md:max-w-xs mt-2  hidden md:block"}>You can
                            add more collections
                            selecting the following option at the top of the collection list: <kbd
                                className="kbd"><IoAddOutline/></kbd></p>
                    </ul>}
            </div>
        </div>
    );
}